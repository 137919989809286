import React from 'react';
import { Pagination } from 'react-bootstrap';

import { ChevronsRight, ChevronsLeft } from 'react-feather';
import { paginate } from '../../utils/utils';


function CustomPagination({data, pageInfo, apiPath, getFunction}) {

    return (
        <>
        {
            data.length && pageInfo.links && pageInfo.links.length  && 1 !== Number(pageInfo.lastPage)? 
                <div className="row align-items-center">
                    <div className="col-12 mb-3 mb-lg-0 col-lg-3 text-center text-lg-start">
                        {`Showing ${pageInfo.firstItemIndex} to ${pageInfo.lastItemIndex} of ${pageInfo.totalPages}`}
                    </div>
                    <div className="col-12 col-lg-9 mb-3 mb-lg-0 d-flex justify-content-center justify-content-lg-end">
                        <Pagination className="mb-0">
                            {
                                pageInfo.links.map( (l, pos) => (
                                    <Pagination.Item key={`p-${pos}`} active={l.active} disabled={!l.url || !l.url.length} onClick={ (e) => {
                                        e.preventDefault();
                                        paginate(l.url, apiPath, getFunction)

                                    }}>
                                        {
                                            l.label.includes('Previous') ?
                                                <ChevronsLeft style={{height: "1em"}} />
                                                : l.label.includes('Next')
                                                ? <ChevronsRight style={{height: "1em"}} />
                                            :
                                            l.label

                                        }
                                    </Pagination.Item>
                                ))
                            }
                        </Pagination>
                    </div>
                
                </div>
            : ''
        }
        </>
    )
}

export default CustomPagination;
