import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const linkBlockLimit = 20;

const isProd = true;

export const apiBaseUrl = isProd ? 'https://mrp.bz/api' : 'http://short.links:8000/api';
export const shortLinkHost = isProd ? 'https://mrp.bz' : 'http://short.links:8000';
export const shortLinkDomain = isProd ? 'mrp.bz' : 'short.links:8000';

/**
 * Show a toast message on the top right corner of the viewport
 * @param {object} customOptions Override the default options
 */
export const showToast = (customOptions) => {

    const SwAlert = withReactContent(Swal);

    let options = {
        title: 'Alert',
        text: '',
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        showConfirmButton: false,
        showClass: {
            popup: 'animate__animated animate__slideInRight animate__faster'
        },
        hideClass: {
            popup: 'animate__delay animate__animated animate__slideOutRight animate__faster'
        },
        target: '.App',
    }

    if (typeof customOptions === 'object') {
        options = { ...options, ...customOptions };
    }

    SwAlert.fire(options)
}

/**
 * Show a popup confirmation message with confirmation options
 * @param {object} customOptions Override the default options
 */

export const showConfirmation = (customOptions, confirmCallback, denyCallback) => {
    const SwAlert = withReactContent(Swal);
    let options = {
        title: '',
        text: '',
        icon: 'error',
        position: 'center',
        showConfirmButton: true,
        showClass: {
            popup: 'animate__animated animate__fadeInUp animate__faster'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutDown animate__faster'
        },
        target: '.App',
    }

    if (typeof customOptions === 'object') {
        options = { ...options, ...customOptions };
    }

    SwAlert.fire(options)
        .then(res => {
            if (res.isConfirmed && typeof confirmCallback === 'function') {
                confirmCallback();
            }

            if (res.isDenied && typeof denyCallback === 'function') {
                denyCallback();
            }
        });
}

/**
 * Copys a string to the clipboard
 * @param {string} text The string to copy
 * @param {function} callback The function to call after copying the text
 */
export const copyToClipboard = (text, callback) => {
    if (!text.length) return;

    navigator.clipboard.writeText(text)
        .then(() => {
            if (typeof callback === "function") callback();
        })
        .catch(() => {
            showToast({
                title: 'Error',
                text: "There was an error while trying to copy the text. Please try again.",
                icon: "error",
            });
        });
}

/**
 * Let's a user download a CSV file from an array of objects
 * @param {array} rows The array of objects to create csv values from
 * @param {string} filename The filename for the downloaded csv
 */
export const generateCSV = (rows, firstLineIsHeaders = true) => {


    let data = [];

    for (let i = 0; i < rows.length; i++) {
        if (i === 0 && firstLineIsHeaders) {
            let headers = Object.keys(rows[i]);
            data.push(headers.join(",").replace(/-|_/g, ' ').toUpperCase());
        }

        let cols = Object.values(rows[i])

        data.push(cols.join(","));
    }

    let csv = data.join("\n");

    let csvFile = new Blob([csv], { type: "text/csv" });

    return csvFile;
}

export const downloadFile = (file, filename) => {
    if (!filename) filename = "file";
    filename = filename.trim().replace(/\s|_/g, '-');
    filename = filename.replace(/[/?%*:|"<>]/g, '-');
    const url = URL.createObjectURL(file);
    const el = document.createElement('a');
    el.href = url;
    el.setAttribute('download', filename);
    el.click();
    el.remove();
}


/**
 * Makes a new API call to get paged data on requested page.
 * @param {string} url The current location url to acquire existing query params from.
 * @param {string} apiPath The path (excluding basepath to the API resource)
 * @param {function} getFunction The function used to call the API resource
 * @returns null
 */
export const paginate = (url, apiPath, getFunction) => {
    if (!url) return;

    //strip url to get page params only
    let arr = url.split('?');
    arr.shift();

    if (arr.length && typeof getFunction === 'function') getFunction(`${apiPath}?${arr[0]}`);
    else return;
}

/**
 * Applies all active filters and navigates
 * @param {event} e the event
 * @param {string} urlPath the path you will navigate to
 * @param {string} apiPath the api path to make a call to the request
 * @param {string} activeQueryString the current active query string
 * @param {function} getFunction the function to make the API call to the resource
 * @param {function} navigateHook useNavigate hook call
 */
export const applyFiltersAndNavigate = (e = null, urlPath, apiPath, activeQueryString, getFunction, navigateHook) => {
    if (e) e.preventDefault();
    navigateHook(`${urlPath}${activeQueryString}`);

    if (typeof getFunction === 'function' && activeQueryString.length) getFunction(`${apiPath}${activeQueryString}`);
}

export const appThemes = [
    {
        name: "Default",
        id: "default",
        isDark: false,
    },

    {
        name: "Dark",
        id: 'dark',
        isDark: true,
    },

];

export const isDarkTheme = (themeID) => {

    if (!themeID) themeID = 'default';
    const needle = appThemes.find((h) => {
        return h.id === themeID;
    });
    return needle.isDark;
}

export const makeQRFileName = (title, campaign = null, extension=".png") => {
    let filename = title.replace(/\s/g, "-");
    if (campaign) {
        filename += '-' + campaign.replace(/\s/g, "-").replace(/_/g, "-");
    }
    
    filename += extension;
    return filename;
}