import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
  
ChartJS.register(ArcElement, Tooltip, Legend);
  
export const PieChart = ({ chartData, options }) => {
  return (
    <div>
      <Pie
        data={chartData}
        options={{...options, scales: {}}}
        height={300}
      />
    </div>
  );
};