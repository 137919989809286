import React, { useState } from 'react';

import API from '../../API/API';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TagSelector from '../../components/TagSelector/TagSelector';
import { HelpCircle } from 'react-feather';
import { labelHTML, removeError } from '../../utils/formUtils';
import { isValidContactLink, isValidShortUrl, isValidURL } from '../../utils/validationUtils';

function CreateLinkRow({ rowID, rows, setRows, divisions, tagSuggestions, setTagSuggestions, rowPosition, rowLinkProps, addRow, updateRow }) {

    const [isFetching, setIsFetching] = useState(false);

    const setErrors = (value) => {
        updateRow(rowPosition, 'errors', value);
    }

    const getLinkDefaults = (e) => {

        const url = e.target.value;

        if (!url.length) {
            setErrors({ ...rowLinkProps.errors, long_url: "Please enter a URL. eg: https://mrpmoney.com or https://www.mrphome.com" })
        }
        else {
            removeError('long_url', rowLinkProps.errors, setErrors)
        }

        if (!isValidURL(url) && !isValidContactLink(url)) {
            // Show Error Message
            setErrors({ ...rowLinkProps.errors, long_url: "Please enter a valid URL. eg: https://mrpmoney.com or https://www.mrphome.com" })
            return;
        }

        if ((!rowLinkProps.hasOwnProperty('title') || rowLinkProps.title.length < 1
            || !rowLinkProps.hasOwnProperty('short_url') || rowLinkProps.short_url.length < 1) && isValidURL(url)) {
            setIsFetching(true);
            API.post('/get-url-meta', { url })
                .then((res) => {
                    const defaults = res.data.content;

                    if (defaults.long_url) {
                        updateRow(rowPosition, 'long_url', defaults.long_url);
                    }

                    if (!rowLinkProps.title.length && defaults.title) {
                        updateRow(rowPosition, 'title', defaults.title);
                        removeError('title', rowLinkProps.errors, setErrors)
                    }

                    if (!rowLinkProps.short_url.length && defaults.short_url) {
                        updateRow(rowPosition, 'short_url', defaults.short_url);
                        removeError('short_url', rowLinkProps.errors, setErrors)
                    }

                    if (!rowLinkProps.utm_campaign.length && defaults.utm_campaign) {
                        updateRow(rowPosition, 'utm_campaign', defaults.utm_campaign);
                        removeError('utm_campaign', rowLinkProps.errors, setErrors)
                    }

                    if (!rowLinkProps.utm_medium.length && defaults.utm_medium) {
                        updateRow(rowPosition, 'utm_medium', defaults.utm_medium);
                        removeError('utm_medium', rowLinkProps.errors, setErrors)
                    }

                    if (!rowLinkProps.utm_source.length && defaults.utm_source) {
                        updateRow(rowPosition, 'utm_source', defaults.utm_source);
                        removeError('utm_source', rowLinkProps.errors, setErrors)
                    }

                    setIsFetching(false);
                })
                .catch((err) => {
                    setIsFetching(false);
                    console.log(err.response)
                })
        }

    }

    const checkURLKeyAvailability = (e) => {

        let key = e.target.value;

        if (!isValidShortUrl(key)) {
            setErrors({ ...rowLinkProps.errors, short_url: "The short link URL key may only contain letters and numbers." });
            return;
        }

        if (!key.length) {
            setErrors({ ...rowLinkProps.errors, short_url: "Please enter a short link URL key." })
        }
        else {
            removeError('short_url', rowLinkProps.errors, setErrors);
        }

        if (key.length) {

            API.get(`link/getAvailability/${key}`)
                .then(res => {
                    if (res.data.available) return;
                    else setErrors({ ...rowLinkProps.errors, short_url: "That URL key is not available." });
                })
                .catch((err) => {
                    console.log(err.response)
                })
        }

    }

    const sanitizeUTM = (value, field) => {
        let newValue = value.trim().replaceAll(" ", '_');
        updateRow(rowPosition, field, newValue);
    }


    // HANDLE TAGS
    const removeTag = (i) => {
        updateRow(rowPosition, 'tags', rowLinkProps.tags.filter((tag, index) => index !== i));
    }

    const clearTags = () => {
        updateRow(rowPosition, 'tags', [])
    }

    const addTag = (tag) => {

        updateRow(rowPosition, 'tags', [...rowLinkProps.tags, tag]);

        // update suggestions
        const newTagI = tagSuggestions.findIndex((i) => i.name.toLowerCase() == tag.name.toLowerCase());
        if (newTagI < 0) {
            setTagSuggestions([...tagSuggestions, tag]);
        }

    };

    const updateTags = (i, newTag) => {
        const updatedTags = rowLinkProps.tags.slice();
        updatedTags.splice(i, 1, newTag);
        // setTags(updatedTags);
        updateRow(rowPosition, 'tags', updatedTags)
    };

    const updateLinkProps = (e, field) => {
        updateRow(rowPosition, field, e.target.value);
    }


    return (
        <>
            <div className="new-link-row d-flex">

                {
                    rows.length > 1 &&
                    <div className="link-num">
                        <div className="vr"></div>
                        <div className={`badge rounded-pill ${Object.keys(rowLinkProps.errors).length ? 'bg-danger' : 'bg-success'} digit`}>{rowPosition + 1}</div>
                        <div className="vr"></div>
                    </div>
                }

                <div className="link-details">

                    <div className="row mb-3">

                        <div className="col-md-6 mb-2 d-flex flex-column flex">

                            <div className="card add-link-card-required h-100 mb-3">
                                <div className="card-header">
                                    <h5 className="m-0">Required Fields</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 mb-3">

                                            {labelHTML('Long URL / Destination', 'long_url', true, `longUrl-${rowID}`, '', rowLinkProps.errors)}

                                            <textarea
                                                id={`longUrl-${rowID}`}
                                                className={`form-control ${rowLinkProps.errors.long_url && rowLinkProps.errors.long_url.length ? 'is-invalid' : ''}`}
                                                value={rowLinkProps.long_url}
                                                onBlur={getLinkDefaults}
                                                onChange={e => updateLinkProps(e, 'long_url')}
                                                required />

                                        </div>

                                        <div className="col-sm-12 col-md-8 mb-3">

                                            {labelHTML('Link Title', 'title', true, rowID, '', rowLinkProps.errors)}

                                            <input
                                                id={`title-${rowID}`}
                                                placeholder={isFetching ? "Checking..." : ''}
                                                className={`form-control ${rowLinkProps.errors.title && rowLinkProps.errors.title.length ? 'is-invalid' : ''}`}
                                                type="text"
                                                value={rowLinkProps.title}
                                                onBlur={(e) => {
                                                    if (!e.target.value) setErrors({ ...rowLinkProps.errors, title: "Please enter a title." })
                                                    else {
                                                        removeError('title', rowLinkProps.errors, setErrors);
                                                    }
                                                }}
                                                onChange={e => updateLinkProps(e, 'title')}
                                                disabled={isFetching}
                                                required />

                                        </div>

                                        <div className="col-sm-12 col-md-4 mb-3">

                                            {labelHTML('Short URL eg. XyzA', 'short_url', true, `shortUrl-${rowID}`, '', rowLinkProps.errors)}

                                            <div className="input-group">
                                                <span className="input-group-text" >mrp.bz/</span>
                                                <input
                                                    id={`shortUrl-${rowID}`}
                                                    placeholder={isFetching ? "Checking..." : ''}
                                                    className={`form-control  ${rowLinkProps.errors.short_url && rowLinkProps.errors.short_url.length ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    value={rowLinkProps.short_url}
                                                    onBlur={checkURLKeyAvailability}
                                                    onChange={e => updateLinkProps(e, 'short_url')}
                                                    disabled={isFetching}
                                                    required />
                                            </div>

                                        </div>

                                        <div className="col-md-6 mb-3">

                                            {labelHTML('Division', 'division', true, rowID, '', rowLinkProps.errors)}

                                            <select
                                                id={`division-${rowID}`}
                                                value={rowLinkProps.division}
                                                className={`form-select  ${rowLinkProps.errors.division && rowLinkProps.errors.division.length ? 'is-invalid' : ''}`}
                                                onChange={e => updateLinkProps(e, 'division')}
                                                required
                                            >
                                                {
                                                    divisions &&
                                                    divisions.map((d, pos) => (
                                                        <option key={`d-${rowID}-${pos}`} value={d.id}>{d.name}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>

                                        <div className="col-md-6 mb-3">

                                            {labelHTML('Privacy', 'privacy', true, rowID, '', rowLinkProps.errors)}

                                            <select
                                                id={`privacy-${rowID}`}
                                                defaultValue="none"
                                                className={`form-select ${rowLinkProps.errors.privacy && rowLinkProps.errors.privacy.length ? 'is-invalid' : ''}`}
                                                onChange={e => updateLinkProps(e, 'privacy')}
                                                required
                                            >
                                                <option value="none">None</option>
                                                <option value="division">Division</option>
                                                <option value="user">Me Only</option>

                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-6 mb-2 d-flex flex-column">
                            <div className="card add-link-card-required flex">
                                <div className="card-header">
                                    <h5 className="m-0">Optional Fields</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 mb-4">

                                            {labelHTML('Tags', 'tags', false, rowID, '', rowLinkProps.errors)}

                                            <TagSelector
                                                options={{
                                                    id: `tags-${rowID}`,
                                                    placeholder: "Add Tags",
                                                    clearAll: true,
                                                    allowUnique: true,
                                                    inline: true,
                                                    allowAdditionFromPaste: true,
                                                    editable: true,
                                                    classNames: {
                                                        tagInputField: rowLinkProps.errors.tags && rowLinkProps.errors.tags.length ? 'is-invalid' : '',
                                                    },
                                                    onTagUpdate: updateTags,
                                                    onClearAll: clearTags,
                                                    handleAddition: addTag,
                                                    handleDelete: removeTag

                                                }}
                                                tags={rowLinkProps.tags}
                                                suggestions={tagSuggestions} />

                                        </div>

                                        <div className="col-12 mb-1 d-flex">
                                            <h5 className="mb-0">UTM Parameters</h5>
                                            <OverlayTrigger
                                                placement="top"
                                                trigger="click"
                                                overlay={
                                                    <Tooltip id={`utm-explain-${rowID}`}>
                                                        UTMs can help you track web traffic in analytics tools.
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="ms-1 d-block cursor-pointer"><HelpCircle size="1em" /></span>
                                            </OverlayTrigger>
                                        </div>

                                        <div className="col-md-6 mb-3">

                                            {labelHTML('Source', 'utm_source', false, rowID, '', rowLinkProps.errors)}

                                            <input
                                                id={`utm_source-${rowID}`}
                                                className={`form-control  ${rowLinkProps.errors.utm_source && rowLinkProps.errors.utm_source.length ? 'is-invalid' : ''}`}
                                                type="text"
                                                value={rowLinkProps.utm_source}
                                                onChange={e => updateLinkProps(e, 'utm_source')}
                                                onBlur={(e) => sanitizeUTM(e.target.value, 'utm_source')}
                                            />
                                        </div>

                                        <div className="col-md-6 mb-3">

                                            {labelHTML('Medium', 'utm_medium', false, rowID, '', rowLinkProps.errors)}

                                            <input
                                                id={`utm_medium-${rowID}`}
                                                className={`form-control  ${rowLinkProps.errors.utm_medium && rowLinkProps.errors.medium.length ? 'is-invalid' : ''}`}
                                                type="text"
                                                value={rowLinkProps.utm_medium}
                                                onChange={e => updateLinkProps(e, 'utm_medium')}
                                                onBlur={(e) => sanitizeUTM(e.target.value, 'utm_medium')}
                                            />
                                        </div>

                                        <div className="col-12 mb-3">

                                            {labelHTML('Campaign', 'utm_campaign', false, rowID, '', rowLinkProps.errors)}

                                            <input
                                                id={`utm_campaign-${rowID}`}
                                                className={`form-control  ${rowLinkProps.errors.utm_campaign && rowLinkProps.errors.utm_campaign.length ? 'is-invalid' : ''}`}
                                                type="text"
                                                value={rowLinkProps.utm_campaign}
                                                onChange={e => updateLinkProps(e, 'utm_campaign')}
                                                onBlur={(e) => sanitizeUTM(e.target.value, 'utm_campaign')}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-12 mt-3 text-end">
                            {
                                <>
                                    {
                                        rows.length > 1 &&
                                        <Button variant="danger" size="sm" className="me-4" onClick={(e) => {
                                            e.preventDefault();
                                            setRows(() => {
                                                const rowsCopy = Array.from(rows).filter((r) => {
                                                    return r.id !== rowID;
                                                })

                                                return rowsCopy;
                                            })
                                        }}>Remove</Button>
                                    }
                                    {
                                        rowPosition + 1 === rows.length &&
                                        <Button variant="info" size="sm" onClick={() => {

                                            let errorsCopy = { ...rowLinkProps.errors };

                                            if (!rowLinkProps.long_url || !rowLinkProps.long_url.length) {
                                                errorsCopy.long_url = "Please enter a URL. eg: https://mrpmoney.com or https://www.mrphome.com";
                                            }

                                            if (!rowLinkProps.title || !rowLinkProps.title.length) {
                                                errorsCopy.title = "Please enter a title.";
                                            }

                                            if (!rowLinkProps.short_url || !rowLinkProps.short_url.length) {
                                                errorsCopy.short_url = "Please enter a short link URL key.";
                                            }

                                            setErrors(errorsCopy);

                                            addRow();
                                        }}>Add Another Link</Button>
                                    }

                                </>
                            }
                        </div>

                    </div>
                </div>
            </div>

            <hr />
        </>
    );
}

export default CreateLinkRow;
