import redcap from './redcap.png';
import ssHeart from './ssHeart.png';
import miladysM from './miladysM.png';
import mrpCard from './mrpCard.png';
import milCard from './milCard.png';
import ssCard from './ssCard.png';
import mrpCard2 from './mrpCard2.png';
import milCard2 from './milCard2.png';
import ssCard2 from './ssCard2.png';

export const QRIcons = [
    {
        name: "redcap",
        icon: redcap,
        color: '215, 25, 32, 215, 25, 32',
        alt: "Mr Price Red Cap",
    },
    {
        name: "miladysM",
        icon: miladysM,
        color: '0,0,0,0,0,0',
        alt: "Miladys M",
    },
    {
        name: "ssHeart",
        icon: ssHeart,
        color: '241, 110, 33, 241, 110, 33',
        alt: "Sheet Street Heart",
    },
    {
        name: "mrpCard",
        icon: mrpCard,
        color: '215, 25, 32, 215, 25, 32',
        alt: "Mr Price Money Card",
    },
    {
        name: "milCard",
        icon: milCard, 
        color: '0,0,0,0,0,0', 
        alt: "Miladys Card",
    },
    {
        name: "ssCard",
        icon: ssCard,
        color: '241, 110, 33, 241, 110, 33',
        alt: "Sheet Street Card",
    },
    {
        name: "mrpCard2",
        icon: mrpCard2,
        color: '215, 25, 32, 215, 25, 32',
        alt: "Mr Price Money Card Horizonal",
    },
    {
        name: "milCard2",
        icon: milCard2,
        color: '0,0,0,0,0,0',
        alt: "Miladys Card Horizonal",
    },
    {
        name: "ssCard2",
        icon: ssCard2,
        color: '241, 110, 33, 241, 110, 33',
        alt: "Sheet Street Card Horizonal",
    },
];

export const getIconByName = (name) => {
    if(!name) return null;
    let i = QRIcons.findIndex( (x) => {
        return x.name === name;
    });

    return QRIcons[i];
}