import axios from 'axios';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { apiBaseUrl } from '../utils/utils';

const SwAlert = withReactContent(Swal)

function getToken() {
    let token = null;
    if(localStorage.getItem('user')) {
        const authUser = JSON.parse(localStorage.getItem('user'));
        if(authUser.token){
            token = JSON.parse(localStorage.getItem('user')).token;
        }
    }
    return token;
}

axios.defaults.baseURL = apiBaseUrl;
axios.defaults.withCredentials = true;

class DatabaseFunctions {

    createToast(message) {
        SwAlert.fire({
            title: message,
            icon: 'error',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            target: '.App',
        })
    }

    async authCall(method, path, params) {

        const res = await this.authCheck();
        if (res === 1) {

            if (method.toLowerCase() === "post")
                return axios.post(path, params);
            else if (method.toLowerCase() === "put")
                return axios.put(path, params);
            else if (method.toLowerCase() === "delete")
                return axios.delete(path, params);
            else
                return axios.get(path, params);
        }
        else {
            
            localStorage.removeItem('user');
            window.location.href = '/login';
            return;
        }
    }

    async authCheck() {
        if(getToken()) axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`; 
        axios.defaults.headers.common["Accept"] = "application/json, text/plain, */*"; 
        const res = await axios.get('authcheck')
        return res.data;
    }
    
    async get(path, params, checkAuth = true) {
       
        if(checkAuth) {
            return this.authCall('get', path, {params})
            .then( res =>  (res))
            .catch(err => {
                if(err && err.response && err.response.data && err.response.data.message) {
                    this.createToast(err.response.data && err.response.data.message );
                }
                console.log(err); 
                return err; 
            })
        }
        else {
            return axios.get(path, {params});
        }
        
    }

    async post(path, params, checkAuth = true) {
        if(checkAuth) {
            return await this.authCall('post', path, params)
            .then( res =>  (res) )
            .catch(err => {
                this.createToast(err.response.data.message);
                return err;
            })
        }
        else {
            return await axios.post(path, params);
        }
    }

    async put(path, params, checkAuth = true) {
        if(checkAuth) {
            return this.authCall('put', path, params)
            .then( res =>  (res))
            .catch(err => {
                this.createToast(err.response.data.message);
                return err;
            })
        }
        else {
            return axios.put(path, params);
        }
        
    }

    async delete(path, params, checkAuth = true ) {
        if(checkAuth) {
            return this.authCall('delete', path, params)
            .then( res =>  (res))
            .catch(err => {
                this.createToast(err.response.data.message);
                return err;
            })
        }
        else {
            return axios.delete(path, params);
        }

    }
}

const API = new DatabaseFunctions();
export default API;