import { objIsEmpty, removeEmptyPropsFromObj } from "./objectUtils";

/**
 * Get an object containing all queries in the current url
 * @returns {object} An object containing each query
 */
export const getQueryParams = () => {
    const queryString = window.location.search;
    if (!queryString.length) return null;

    const urlParams = new URLSearchParams(queryString);
    const entries = urlParams.entries();
    const ob = {};
    for (const entry of entries) {
        ob[entry[0]] = entry[1];
    }
    return ob;
}

/**
 * Convert an object of queries to a queries string
 * @param {object} obj The object of query paramaters eg. {search: 'Hello World'}
 * @returns {string} query string or empty string if there are no queries
 */
export const makeSearchQuery = (obj) => {
    if (objIsEmpty(obj)) return '';
    const params = removeEmptyPropsFromObj(obj);
    const str = new URLSearchParams(params).toString()
    return str.length ? '?' + str : '';
}

/**
 * Check if the current url has queries
 * @returns {boolean} true or false
 */
export const currentUrlHasQueries = () => {
    const params = removeEmptyPropsFromObj(getQueryParams());
    return Object.keys(params).length > 0;
}