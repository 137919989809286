import React from 'react'
import './CSSSpinner.css';

function CSSSpinner() {
  return (
    <div className="spinner-wrapper">
        <div className="spinner"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default CSSSpinner