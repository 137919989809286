import { createContext, useReducer } from "react"
import AuthReducer from "./AuthReducer"

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [authUser, authDispatch] = useReducer(AuthReducer, JSON.parse(localStorage.getItem("user")) || null);

  return (
    <AuthContext.Provider value={{ authUser, authDispatch }} >
      {children}
    </AuthContext.Provider>
  );
};