import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HelpCircle } from 'react-feather';
import { useLocation, useParams } from 'react-router-dom';
import API from '../../API/API';
import TagSelector from '../../components/TagSelector/TagSelector';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { PageContext } from '../../context/pageContext';
import { linkBlockLimit, showConfirmation, showToast } from '../../utils/utils';
import { isValidShortUrl } from '../../utils/validationUtils';

function EditLink() {

    const { setPageInfo } = useContext(PageContext);
    const { setShowLoader } = useContext(LoaderContext);
    const [divisions, setDivisions] = useState([]);
    const [link, setLink] = useState(null);
    const [originalShortKey, setOriginalShortKey] = useState(null);
    const [errors, setErrors] = useState({});
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [tags, setTags] = useState([]);
    const { authUser } = useContext(AuthContext);

    const loc = useLocation();
    const params = useParams();

    const getDivisionsAndTags = () => {

        API.get('divisions')
            .then((res) => {
                setDivisions(res.data.content);
            })
            .catch((err) => {
                setShowLoader(false);
            });

        API.get('tags/all')
            .then((res) => {
                const gotTags = res.data.content.map((t) => {
                    return {
                        id: t.id.toString(),
                        name: t.name,
                    }
                })
                setTagSuggestions(gotTags);
                setShowLoader(false);

            })
            .catch((err) => {
                setShowLoader(false);
            });
    }

    const tagsToArray = (t) => {
        const linkTags = t.map((t, pos) => {
            return {
                id: `${t}-${pos}`,
                name: t
            }
        });
        return linkTags;
    }

    const tagsToString = () => {
        if (!Array.isArray(tags)) return '';

        const names = tags.map((t) => {
            if (t.name) return t.name;
        });

        return names.join(',');
    }

    useEffect(() => {
        setShowLoader(true);
        setPageInfo({ title: ``, path: loc.pathname, hasCreate: false });
        API.get(`link/${params.id}`)
            .then((res) => {
                getDivisionsAndTags();

                const lnk = res.data.content;


                setPageInfo({ title: `Edit ${lnk.title} (mrp.bz/${lnk.short_url})`, path: loc.pathname, hasCreate: false });

                setLink({ ...lnk, division: lnk.division.id });
                setOriginalShortKey(lnk.short_url);

                if (lnk && lnk.tags && lnk.tags.length) {
                    const linkTags = tagsToArray(lnk.tags);
                    setTags(linkTags);
                }
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err.response)
                setShowLoader(false);
            })
    }, []);

    const errorIndicator = (text = "Invalid input data.") => {
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip>
                        {text}
                    </Tooltip>
                }
            >
                <HelpCircle size="1em" color="red" />
            </OverlayTrigger>
        )
    }

    const labelHTML = (label, prop, required = false, classNames = "") => {
        return (
            <div className="d-flex justify-content-between">
                <label htmlFor={`${prop}-${link.id}`} className={`${required ? 'required' : ''} ${classNames}`} >{label}</label>
                {
                    errors[prop] && errors[prop].length &&
                    errorIndicator(errors[prop])
                }
            </div>
        )
    }

    const removeError = (field) => {
        let errorsCopy = { ...errors };
        delete errorsCopy[field];
        setErrors(errorsCopy);
    }

    const checkURLKeyAvailability = (e) => {

        let key = e.target.value;

        if (!isValidShortUrl(key)) {
            setErrors({ ...errors, short_url: "The short link URL key may only contain letters and numbers." });
        }
        else if (!key.length) {
            setErrors({ ...errors, short_url: "Please enter a short link URL key." })
        }
        else {
            removeError('short_url');
        }

        if (key === originalShortKey) {
            return;
        }

        if (key.length) {

            API.get(`link/getAvailability/${key}`)
                .then(res => {
                    if (res.data.available > 0) return;
                    else setErrors({ ...errors, short_url: "That URL key is not available." });
                })
                .catch((err) => {
                    console.log(err.response)
                })
        }

    }

    const updateTags = (i, newTag) => {
        const updatedTags = [...tags].splice(i, 1, newTag);
        setTags(updatedTags);
    };

    const clearTags = () => {
        setTags([]);
    }

    const addTag = (tag) => {

        setTags([...tags, tag]);

        // update suggestions
        const newTagI = tagSuggestions.findIndex((i) => i.name.toLowerCase() == tag.name.toLowerCase());
        if (newTagI < 0) {
            setTagSuggestions([...tagSuggestions, tag]);
        }

    };

    const removeTag = (i) => {
        const filteredTags = [...tags].filter((tag, index) => index !== i);
        setTags(filteredTags);
    }

    const sanitizeUTM = (value, field) => {
        let newValue = value.trim().replaceAll(" ", '_');
        setLink({ ...link, [field]: newValue })
    }

    const formattedLink = () => {
        return {
            id: link.id,
            long_url: link.long_url,
            title: link.title,
            short_url: link.short_url,
            division: link.division,
            privacy: link.privacy,
            utm_source: link.utm_source,
            utm_medium: link.utm_medium,
            utm_campaign: link.utm_campaign,
            tags: tags && tags.length ? tagsToString() : ''
        }
    }

    const updateLink = (lnk) => {
        setShowLoader(true);
        API.put(`link/update/${link.id}`, lnk)
            .then((res) => {

                if (res.status === 200) {
                    if (res.data && res.data.content) {
                        const updatedLink = res.data.content;
                        setLink({ ...updatedLink, division: updatedLink.division.id });
                        showToast(
                            {
                                title: `${updatedLink.title} updated successfully`,
                            }
                        );
                    }
                }
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
                console.log(err.response)
            })
    }

    const saveChanges = () => {

        const lnk = formattedLink();

        if (lnk.privacy === 'division' && Number(lnk.division) !== Number(authUser.division.id) && Number(link.user.id) !== Number(authUser.id)) {

            const proceed = () => {
                updateLink(lnk);
            }
            showConfirmation({
                title: "Caution",
                text: `Are you sure you want to change the privacy of this division. You won't be able to view this link anymore.`,
                position: 'center',
                showConfirmButton: true,
                showCancelButton: true,
                icon: 'warning',
            }, proceed)
        }
        else {
            updateLink(lnk);
        }
    }

    return (
        <Container fluid>
            {
                link ?

                    authUser.isAdmin || link.created_by == authUser.id ?

                        <div className="new-link-row d-flex">

                            <div className="link-details">

                                <div className="row mb-3">

                                    <div className="col-md-6 mb-4 d-flex flex-column flex">

                                        <div className="card add-link-card-required h-100">
                                            <div className="card-header">
                                                <h5 className="card-heading">Required Fields</h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 mb-3">

                                                        {labelHTML('Long URL / Destination', 'long_url', true)}

                                                        <textarea
                                                            id={`longUrl-${link.id}`}
                                                            className={`form-control ${errors.long_url && errors.long_url.length ? 'is-invalid' : ''}`}
                                                            value={link.long_url}
                                                            disabled={link.hits >= linkBlockLimit}
                                                            onBlur={(e) => {
                                                                if (!e.target.value) setErrors({ ...errors, long_url: "Please enter a long URL." })
                                                                else {
                                                                    removeError('long_url')
                                                                }
                                                            }
                                                            }
                                                            onChange={e => setLink({ ...link, long_url: e.target.value })}
                                                            required />
                                                        {
                                                            link.hits >= linkBlockLimit &&
                                                            <small className="text-danger">NB: You can only change the destination URL for links with less than {linkBlockLimit} clicks.</small>
                                                        }

                                                    </div>

                                                    <div className="col-sm-12 col-md-8 mb-3">

                                                        {labelHTML('Link Title', 'title', true)}

                                                        <input
                                                            id={`title-${link.id}`}

                                                            className={`form-control ${errors.title && errors.title.length ? 'is-invalid' : ''}`}
                                                            type="text"
                                                            value={link.title}
                                                            onBlur={(e) => {
                                                                if (!e.target.value) setErrors({ ...errors, title: "Please enter a title." })
                                                                else {
                                                                    removeError('title')
                                                                }

                                                            }}
                                                            onChange={e => setLink({ ...link, title: e.target.value })}
                                                            required />

                                                    </div>

                                                    <div className="col-sm-12 col-md-4 mb-3">

                                                        {labelHTML('Short URL eg. XyzA', 'short_url', true)}

                                                        <div className="input-group">
                                                            <span className="input-group-text" >mrp.bz/</span>
                                                            <input
                                                                id={`shortUrl-${link.id}`}
                                                                className={`form-control  ${errors.short_url && errors.short_url.length ? 'is-invalid' : ''}`}
                                                                type="text"
                                                                value={link.short_url}
                                                                onBlur={checkURLKeyAvailability}
                                                                onChange={e => setLink({ ...link, short_url: e.target.value })}
                                                                required />
                                                        </div>

                                                    </div>

                                                    <div className="col-md-6 mb-3">

                                                        {labelHTML('Division', 'division', true)}

                                                        <select
                                                            id={`division-${link.id}`}
                                                            value={link.division}
                                                            className={`form-select  ${errors.division && errors.division.length ? 'is-invalid' : ''}`}
                                                            onChange={e => setLink({ ...link, division: e.target.value })}
                                                            required
                                                        >
                                                            {
                                                                divisions &&
                                                                divisions.map((d, pos) => (
                                                                    <option key={`d-${d.id}-${pos}`} value={d.id}>{d.name}</option>
                                                                ))
                                                            }

                                                        </select>
                                                    </div>

                                                    <div className="col-md-6 mb-3">

                                                        {labelHTML('Privacy', 'privacy', true)}

                                                        <select
                                                            id={`privacy-${link.id}`}
                                                            value={link.privacy && link.privacy.link ? link.privacy : 'none'}
                                                            className={`form-select ${errors.privacy && errors.privacy.length ? 'is-invalid' : ''}`}
                                                            onChange={e => setLink({ ...link, privacy: e.target.value })}
                                                            required
                                                        >
                                                            <option value="none">None</option>
                                                            <option value="division">Division</option>
                                                            <option value="user">Me Only</option>

                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-2 d-flex flex-column">
                                        <div className="card add-link-card-required flex">
                                            <div className="card-header">
                                                <h5 className="card-heading">Optional Fields</h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 mb-4">

                                                        {labelHTML('Tags', 'tags', false)}

                                                        <TagSelector
                                                            options={{
                                                                id: `tags-${link.id}`,
                                                                placeholder: "Add Tags",
                                                                clearAll: true,
                                                                allowUnique: true,
                                                                inline: true,
                                                                allowAdditionFromPaste: true,
                                                                editable: true,
                                                                classNames: {
                                                                    tagInputField: errors.tags && errors.tags.length ? 'is-invalid' : '',
                                                                },
                                                                onTagUpdate: updateTags,
                                                                onClearAll: clearTags,
                                                                handleAddition: addTag,
                                                                handleDelete: removeTag

                                                            }}
                                                            tags={tags || []}
                                                            suggestions={tagSuggestions} />

                                                    </div>

                                                    <div className="col-12 mb-1 d-flex">
                                                        <h5 className="mb-0">UTM Parameters</h5>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            trigger="click"
                                                            overlay={
                                                                <Tooltip id={`utm-explain-${link.id}`}>
                                                                    UTMs can help you track web traffic in analytics tools.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="ms-1 d-block cursor-pointer"><HelpCircle size="1em" /></span>
                                                        </OverlayTrigger>
                                                    </div>

                                                    <div className="col-md-6 mb-3">

                                                        {labelHTML('Source', 'utm_source', false)}

                                                        <input
                                                            id={`utm_source-${link.id}`}
                                                            className={`form-control  ${errors.utm_source && errors.utm_source.length ? 'is-invalid' : ''}`}
                                                            type="text"
                                                            value={link.utm_source}
                                                            onChange={e => setLink({ ...link, utm_source: e.target.value })}
                                                            onBlur={(e) => sanitizeUTM(e.target.value, 'utm_source')}
                                                        />
                                                    </div>

                                                    <div className="col-md-6 mb-3">

                                                        {labelHTML('UTM Medium', 'utm_medium', false)}

                                                        <input
                                                            id={`utm_medium-${link.id}`}
                                                            className={`form-control  ${errors.utm_medium && errors.medium.length ? 'is-invalid' : ''}`}
                                                            type="text"
                                                            value={link.utm_medium}
                                                            onChange={e => setLink({ ...link, utm_medium: e.target.value })}
                                                            onBlur={(e) => sanitizeUTM(e.target.value, 'utm_medium')}
                                                        />
                                                    </div>

                                                    <div className="col-12 mb-3">

                                                        {labelHTML('Campaign', 'utm_campaign', false)}

                                                        <input
                                                            id={`utm_campaign-${link.id}`}
                                                            className={`form-control  ${errors.utm_campaign && errors.utm_campaign.length ? 'is-invalid' : ''}`}
                                                            type="text"
                                                            value={link.utm_campaign}
                                                            onChange={e => setLink({ ...link, utm_campaign: e.target.value })}
                                                            onBlur={(e) => sanitizeUTM(e.target.value, 'utm_campaign')}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-12 mt-3 d-flex justify-content-end">
                                        {/* <Button variant="secondary" onClick={ () => {

                                    }}>Go Back</Button> */}
                                        <Button variant="success" onClick={saveChanges}>Save Changes</Button>
                                    </div>

                                </div>
                            </div>
                        </div> : <div className="alert alert-warning">You don't have permission to edit this link.</div>
                    : ''
            }
        </Container>
    );
}

export default EditLink;
