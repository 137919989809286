import React, { useContext, useEffect, useRef, useState } from 'react';
import { Badge, Button, Container, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { useLocation, useParams } from "react-router-dom";
import API from '../../../API/API';
import { BarChart } from '../../../components/Charts/BarChart';
import { chartData, chartDataMulti, chartLabels, chartOptions, themeChartColors } from '../../../components/Charts/ChartUtils.js';
import { LineChart } from '../../../components/Charts/LineChart';
import { PieChart } from '../../../components/Charts/PieChart';
import { LoaderContext } from '../../../context/loaderContext';
import { PageContext } from '../../../context/pageContext';
import { copyToClipboard, isDarkTheme, shortLinkDomain, shortLinkHost, showToast } from '../../../utils/utils';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import './ShowLink.css';
import { DonutChart } from '../../../components/Charts/DonutChart';
import { AuthContext } from '../../../context/authContext/AuthContext';

function ShowLink(props) {

    const today = new Date();
    const lastWeek = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));

    const params = useParams();

    const { setPageInfo } = useContext(PageContext);
    const { authUser } = useContext(AuthContext);
    const [link, setLink] = useState(null);
    const [linkStats, setLinkStats] = useState(null);
    const { showLoader, setShowLoader } = useContext(LoaderContext);
    const [queryPeriod, setQueryPeriod] = useState({ start: lastWeek, end: today });
    const [dateRange, setDateRange] = useState(7);
    const loc = useLocation();

    const getLink = () => {
        setShowLoader(true);
        API.get(`/link/${params.id}`)
            .then((res) => {
                setLink(res.data.content)
                const linkTitle = res.data.content.title;
                const shortLink = res.data.content.short_url;
                setPageInfo({ title: `${linkTitle} (${shortLinkDomain}/${shortLink})`, path: loc.pathname, hasCreate: false });
                getLinkStats(queryPeriod.start, queryPeriod.end);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    const getLinkStats = (from, to) => {

        if (!showLoader) setShowLoader(true);

        API.get(`link/stats/${params.id}`, { fromDate: from, toDate: to })
            .then((res) => {
                setLinkStats(res.data.content)
                setShowLoader(false);

            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    useEffect(() => {

        if (!params.id) return props.history.push("/dashboard")
        setPageInfo({ title: ``, path: loc.pathname, hasCreate: false });
        getLink();

        return () => {
            setShowLoader(true);
            setLink(null);
        }

    }, []);

    const copyLink = (key) => {
        const t = showToast('Short Link Copied', "");
        copyToClipboard(`${shortLinkHost}/${key}`, t);
    }

    const multiChartColors = (pos) => {
        const style = themeChartColors[authUser.theme] || themeChartColors['default'];
        let dataColors;
        if (style.data) {
            dataColors = style.data;
        }
        else {
            dataColors = themeChartColors['default'].data;
        }

        return dataColors[pos] || themeChartColors['default'].data[0];
    }

    const updateDateRange = (e) => {
        const q = e.target.value;

        setDateRange(q);

        if (q < 0) return;
        const startDate = new Date(today.getTime() - (q * 24 * 60 * 60 * 1000));
        const endDate = Number(q) === 1 ? new Date(today.getTime() - (1 * 24 * 60 * 60 * 1000)) : today;

        setQueryPeriod({ start: startDate, end: endDate });
        getLinkStats(startDate, endDate);
    }

    const popoverParent = useRef(null);

    //Stats
    const browsers = linkStats?.browsers ?? {};
    const devices = linkStats?.devices ?? {};
    const countries = linkStats?.countries ?? {};
    const cities = linkStats?.cities ?? {};
    const regions = linkStats?.regions ?? {};
    const referrers = linkStats?.referrers ?? {};

    return (
        <Container fluid>
            {
                !link && !showLoader ?
                    <div className="alert alert-danger">Couldn't get link data</div>
                    :
                    link ?
                        <>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="card-heading">LINK INFO</h5>
                                                </div>
                                                <div className="card-body">
                                                    <Table className="link-info-table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Title:</td>
                                                                <td colSpan={2}>{link.title}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Division:</td>
                                                                <td colSpan={2}>
                                                                    <img src={isDarkTheme(authUser.theme) ? link.division.logo_alt : link.division.logo} alt={link.division.name} style={{ width: "auto", height: "1em" }} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Short URL:</td>
                                                                <td><a href={`${shortLinkHost}/${link.short_url}`} target="_blank" rel="noreferrer"><span className="ant-typography">{shortLinkDomain}/</span><strong>{link.short_url}</strong></a></td>
                                                                <td>
                                                                    <Button
                                                                        variant="success"
                                                                        className="ms-3 cursor-pointer"
                                                                        size="sm"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            copyLink(link.short_url)
                                                                        }}
                                                                    >
                                                                        copy
                                                                    </Button>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Long URL:</td>
                                                                <td colSpan={2}><a href={link.long_url} target="_blank" rel="noreferrer">{link.long_url}</a>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Total Clicks:</td>
                                                                <td colSpan={2}>
                                                                    {
                                                                        linkStats && linkStats.hits &&
                                                                        <>{linkStats.hits.total} {linkStats.hits.total > 0 ? `(${linkStats.hits.total_unique} unique)` : ''}</>
                                                                    }
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mb-4">
                                            <div className="card" >

                                                <OverlayTrigger
                                                    trigger="click"
                                                    placement="bottom-start"
                                                    container={popoverParent}
                                                    rootClose={true}
                                                    overlay={
                                                        <Popover id={`popover-link-creator`}>

                                                            <Popover.Body>
                                                                <div className="user-pop text-center">
                                                                    <div className="link-creator mx-auto">
                                                                        <img src={link.user?.avatar} alt="" />
                                                                    </div>
                                                                    <hr />
                                                                    <div className="link-creator-name">
                                                                        {link.user?.firstname} {link.user?.lastname}
                                                                    </div>

                                                                    {
                                                                        link.division?.logo ?
                                                                            <>
                                                                                <hr />
                                                                                <div className="col-auto link-user-division-logo">
                                                                                    <img src={isDarkTheme(authUser.theme) ? link.user?.division?.logo_alt : link.user?.division?.logo} alt={link.user?.division?.name} />
                                                                                </div>
                                                                            </>
                                                                            : ''
                                                                    }

                                                                    {
                                                                        link.user?.email ?
                                                                            <>
                                                                                <hr />
                                                                                <div>
                                                                                    <a href={`mailto:${link.user?.email}`} >{link.user?.email}</a>
                                                                                </div>
                                                                            </>
                                                                            : ''
                                                                    }


                                                                </div>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >

                                                    <div className="card-header">
                                                        <h5 className="card-heading">CREATED BY <span style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }} className="cursor-pointer" ref={popoverParent}>{link.user?.firstname} {link.user?.lastname}</span></h5>
                                                    </div>
                                                </OverlayTrigger>


                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-12 col-md-6">

                                    <div className="row">

                                        {
                                            link.tags.length ?
                                                <div className="col-12 mb-4">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="card-heading">TAGS</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            {
                                                                link.tags.map((tag, pos) => (

                                                                    <Badge
                                                                        bg="primary"
                                                                        className="link-tag me-2 rounded-pill"
                                                                        size="sm"
                                                                        key={`l-${pos}`}
                                                                    >
                                                                        {tag}
                                                                    </Badge>

                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                        {
                                            link.other_links.length ?
                                                <div className="col-12 mb-4">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="card-heading">PREVIOUS LINKS</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            {
                                                                link.other_links.map((l, pos) => (

                                                                    <Badge
                                                                        bg="primary"
                                                                        className="link-tag me-2"
                                                                        size="sm"
                                                                        key={`t-${pos}`}
                                                                    >
                                                                        {l}
                                                                    </Badge>

                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                        {
                                            link.utm_campaign || link.utm_source || link.utm_medium ?
                                                <div className="col-12 mb-4">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="card-heading">UTM PARAMETERS</h5>
                                                        </div>
                                                        <div className="card-body">

                                                            <Table className="link-info-table">
                                                                <tbody>
                                                                    {
                                                                        link.utm_campaign ?
                                                                            <tr>
                                                                                <td>Campaign:</td>
                                                                                <td>{link.utm_campaign}</td>
                                                                            </tr>
                                                                            : ''
                                                                    }

                                                                    {
                                                                        link.utm_source ?
                                                                            <tr>
                                                                                <td>Source:</td>
                                                                                <td>{link.utm_source}</td>
                                                                            </tr>
                                                                            : ''
                                                                    }

                                                                    {
                                                                        link.utm_medium ?
                                                                            <tr>
                                                                                <td>Medium:</td>
                                                                                <td>{link.utm_medium}</td>
                                                                            </tr>
                                                                            : ''
                                                                    }

                                                                </tbody>
                                                            </Table>

                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                    </div>

                                </div>

                            </div>

                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="row ">
                                        <div className="col-12 col-xl-3 d-flex align-items-center mb-3 mb-xl-0 ">
                                            <h5 className="card-heading">LINK STATS</h5>
                                        </div>

                                        <div className="col-12 col-md-4 col-xl-3 mb-2 mb-md-0">
                                            <div className="input-group" style={{ flexWrap: "nowrap" }}>
                                                <label htmlFor="q-range" className="input-group-text cursor-pointer" >Period</label>
                                                <select
                                                    id="q-range"
                                                    className="form-select"
                                                    onChange={updateDateRange}
                                                    value={dateRange}
                                                >
                                                    <option value={-1}>Custom</option>
                                                    <option value={0}>Today</option>
                                                    <option value={1}>Yesterday</option>
                                                    <option value={7}>Last 7 Days</option>
                                                    <option value={30}>Last 30 Days</option>
                                                    <option value={90}>Last 90 Days</option>
                                                    <option value={180}>Last 180 Days</option>
                                                    <option value={365}>Last 365 days</option>

                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4 col-xl-3 d-flex align-items-center mb-2 mb-md-0">

                                            <div className="input-group" style={{ flexWrap: "nowrap" }}>
                                                <label htmlFor="q-from" className="input-group-text cursor-pointer" >From</label>
                                                <DatePicker
                                                    id="q-from"
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    selected={queryPeriod.start}
                                                    onChange={(date) => {
                                                        setDateRange(-1);
                                                        if (queryPeriod.end < date) {
                                                            setQueryPeriod({ start: date, end: date });
                                                            getLinkStats(date, date);
                                                        }
                                                        else {
                                                            setQueryPeriod({ ...queryPeriod, start: date });
                                                            getLinkStats(date, queryPeriod.end);
                                                        }

                                                    }}
                                                />
                                            </div>

                                        </div>

                                        <div className="col-12 col-md-4 col-xl-3 d-flex align-items-center mb-2 mb-md-0">
                                            <div className="input-group" style={{ flexWrap: "nowrap" }}>
                                                <label htmlFor="q-to" className="input-group-text cursor-pointer" >To</label>
                                                <DatePicker
                                                    id="q-to"
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    selected={queryPeriod.end}
                                                    onChange={(date) => {
                                                        setDateRange(-1);
                                                        if (queryPeriod.start > date) {
                                                            setQueryPeriod({ start: date, end: date });
                                                            getLinkStats(date, date);
                                                        }
                                                        else {
                                                            setQueryPeriod({ ...queryPeriod, end: date });
                                                            getLinkStats(queryPeriod.start, date);
                                                        }
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            {
                                linkStats && linkStats.hits && linkStats.hits.queried_period > 0 ?
                                    <>

                                        <div className="row gx-5 gy-4">

                                            <div className="col-md-12 mb-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">CLICKS</h5>
                                                    </div>
                                                    <div className="card-body">

                                                        <LineChart chartData={chartDataMulti(chartLabels(linkStats.hits_by_date),
                                                            [
                                                                {
                                                                    label: `All (${linkStats.hits.queried_period})`,
                                                                    data: Object.keys(linkStats.hits_by_date).map((i) => {
                                                                        return linkStats.hits_by_date[i].total;
                                                                    }),
                                                                    backgroundColor: multiChartColors(0),
                                                                    borderColor: multiChartColors(0),
                                                                    fill: true,
                                                                    borderWidth: 2,

                                                                },
                                                                {
                                                                    label: `Unique (${linkStats.hits.unique_in_queried_period})`,
                                                                    data: Object.keys(linkStats.hits_by_date).map((i) => {
                                                                        return linkStats.hits_by_date[i].unique;
                                                                    }),
                                                                    backgroundColor: multiChartColors(1),
                                                                    borderColor: multiChartColors(1),
                                                                    fill: true,
                                                                    borderWidth: 2,
                                                                }
                                                            ]
                                                            , [])} options={chartOptions('', { show: true, align: 'center', position: 'top' }, authUser.theme || 'default')} height={100} />

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-md-4 mb-4 ">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">BROWSERS</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <BarChart chartData={chartData('', chartLabels(browsers, 3), browsers, authUser.theme, [])} options={chartOptions('', {}, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 mb-4 ">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">DEVICES</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <PieChart chartData={chartData('', chartLabels(devices, 3), devices, authUser.theme, [], { mobile: "Mobile", other: "Desktop/TV/Other", tablet: "Tablet" })} options={chartOptions('', { show: true }, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 mb-4 ">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">REFERRERS</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <PieChart chartData={chartData('', chartLabels(referrers, 5), referrers, authUser.theme, [], { direct: "Direct/Email/SMS" })} options={chartOptions('', { show: true }, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 mb-4 ">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">REGIONS</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <BarChart chartData={chartData('', chartLabels(regions, 5), regions, authUser.theme, [])} options={chartOptions('', {}, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 mb-4 ">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">CITIES</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <DonutChart chartData={chartData('', chartLabels(cities, 5), cities, authUser.theme, [])} options={chartOptions('', { show: true }, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 mb-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">COUNTRIES</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <BarChart chartData={chartData('', chartLabels(countries, 5), countries, authUser.theme, [])} options={chartOptions('', {}, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </>
                                    :
                                    <div className="alert alert-warning">There are no stats to display for the queried period.</div>
                            }
                        </>
                        : ''

            }

        </Container>
    );
}

export default ShowLink;
