import { createContext, useState } from "react"
  
export const SidebarContext = createContext();

export const SidebarContextProvider = ({ children }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  
  return (
    <SidebarContext.Provider value={{ sidebarIsOpen, setSidebarIsOpen }} >
      {children}
    </SidebarContext.Provider>
  );
};
