import React, { useContext, useEffect, useState } from 'react';
import API from '../../API/API';
import { LoaderContext } from '../../context/loaderContext';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { downloadFile, linkBlockLimit, makeQRFileName, showConfirmation, showToast } from '../../utils/utils';

import './QRCodes.css';
import { currentUrlHasQueries, getQueryParams, makeSearchQuery } from '../../utils/urlUtils';
import CustomPagination from '../../components/Pagination/Pagination';
import QRCodesDesktop from './QRCodesDesktop';
import QRCodesMobile from './QRCodesMobile';
import { getIconByName } from './QRIcons/QRIcons';
import JSZip from 'jszip';


function QRCodes({ apiPath, urlPath, linkFilters }) {

    const [links, setLinks] = useState([]);
    const [filters, setFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState({ division: '', search: '', user: '' })
    const [activeQueryString, setActiveQueryString] = useState('')
    const [divisions, setDivisions] = useState([]);
    const [users, setUsers] = useState([]);

    const { showLoader, setShowLoader } = useContext(LoaderContext);
    const [paginationInfo, setPaginationInfo] = useState({ links: [] });

    const navigate = useNavigate();

    const getFilters = () => {

        API.get('/link-filters')
            .then((res) => {
                
                const filts = res.data.content;
                setDivisions(filts.divisions);
                setUsers(filts.users);
                setShowLoader(false);

            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    const getLinks = (url, blocker = true) => {

        if (!showLoader && blocker) setShowLoader(true);

        API.get(url)
            .then((res) => {
                const content = res.data.content;
                setLinks(content.data);
                setPaginationInfo({
                    links: content.links,
                    currentPage: content.current_page,
                    lastPage: content.last_page,
                    currentCount: content.length,
                    firstItemIndex: content.from,
                    lastItemIndex: content.to,
                    totalPages: content.total,

                })
                getFilters();

            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    useEffect(() => {

        const queries = getQueryParams();
        const str = makeSearchQuery(queries);
        setActiveQueryString(str);

        getLinks(`${apiPath}${str}`);

        if (queries) setActiveFilters(queries);
        else setActiveFilters({ division: '', search: '', user: '' })

        const filts = linkFilters != undefined ? linkFilters : [];
        setFilters(filts);

    }, []);

    const generatePngAndSvg = (id, bg, fg) => {

        return new Promise((resolve) => {
            let phantom = document.createElement('canvas');
            phantom.setAttribute("id", `qr-canvas-${id}`);
            phantom.setAttribute("width", "512");
            phantom.setAttribute("height", "512");
            phantom.setAttribute("style", "position: absolute; left: -100000px; top: -1000000px;");
            document.body.append(phantom);

            let c = phantom;
            let ctx = c.getContext("2d");

            let imageObj1 = new Image();
            imageObj1.src = bg.toString();

            let url;
            imageObj1.onload = () => {
                ctx.drawImage(imageObj1, 0, 0);

                // if (fg && fg.length) {

                //     let imageObj2 = new Image();
                //     imageObj2.src = fg.toString();

                //     imageObj2.onload = () => {
                //         ctx.drawImage(imageObj2, 198, 198, 120, 120);
                //         url = c.toDataURL();
                //         c.remove();
                //         resolve(url);
                //     }

                // }
                // else {
                    url = c.toDataURL();
                    c.remove();
                    return resolve({
                        png: url,
                        svg: bg
                    });
                // }

            }
        })
    }

    const downloadQR = (id, bg, fgName, title, campaign) => {
        let ic = getIconByName(fgName);
        let promises = [];
        promises.push(generatePngAndSvg(id, bg, ic.icon));
        promises.push(generatePngAndSvg(id, bg, ic.icon));
        
       Promise.all(promises).then((responses) => {

            const zip = new JSZip();
            
            responses.forEach((response) => {
                zip.file(makeQRFileName(title, campaign, '.svg'), b64toBlob(response.svg));
                zip.file(makeQRFileName(title, campaign, '.png'), b64toBlob(response.png));
            })

            zip.generateAsync({
                type: "blob"
            }).then(function (content) {
                downloadFile(content, makeQRFileName(title, campaign, '.zip'))
            })
            .catch(errr => {
                console.log(errr)
            });

        })
            .catch(err => {
                showConfirmation({ text: 'There was an error downloading the QR code image.', icon: 'error' })
            });

    }

    const b64toBlob = (data, mimeType = 'image/png') => {

        var string = atob(data.split(',')[1]);
        var buffer = new ArrayBuffer(string.length);
        var ia = new Uint8Array(buffer);

        for (var i = 0; i < string.length; i++) {
            ia[i] = string.charCodeAt(i);
        }
        return new Blob([buffer], { type: mimeType });
    }

    const updateFilters = (name, value) => {

        const filts = { ...activeFilters, [name]: value };
        setActiveFilters(filts);
        const str = makeSearchQuery(filts);
        setActiveQueryString(str);

    }

    const hasActiveFilters = () => {
        return activeFilters.division || activeFilters.search || activeFilters.user || currentUrlHasQueries();
    }

    const deleteLink = (id) => {
        API.delete(`qr/delete/${id}`)
            .then((res) => {

                if (res.status === 200) {

                    const queries = getQueryParams();
                    const str = makeSearchQuery(queries);
                    setActiveQueryString(str);

                    getLinks(`${apiPath}${str}`);

                    showToast({ title: `${res.data.content.title} deleted successfully` });
                }

            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    const deleteLinkConfirm = (link) => {

        const del = () => deleteLink(link.id);

        if (link.scans.total >= linkBlockLimit)
            showConfirmation({ title: "Can't Delete Link", text: "This QR code has had too many clicks and can't be deleted.", icon: 'warning' });
        else
            showConfirmation({ title: "Confirm Deletion", html: <p>Are you sure you want to delete this QR code?<br /> <br />{link.title}<br />{link.url}</p>, icon: 'error', showDenyButton: true }, del);
    }

    return (
        <Container fluid>
            {
                showLoader ? '' :

                !showLoader && links.length > 0 ?

                    <div className="card mb-5">
                        <div className="card-body">

                            <div className="d-xl-none">
                                <QRCodesMobile
                                    urlPath={urlPath}
                                    apiPath={apiPath}
                                    getLinks={getLinks}
                                    navigate={navigate}
                                    hasActiveFilters={hasActiveFilters}
                                    setActiveFilters={setActiveFilters}
                                    links={links}
                                    downloadQR={downloadQR}
                                    deleteLinkConfirm={deleteLinkConfirm}

                                    filters={filters}
                                    divisions={divisions}
                                    users={users}
                                    updateFilters={updateFilters}
                                    activeFilters={activeFilters}
                                    activeQueryString={activeQueryString}
                                />
                            </div>
                            <div className="d-none d-xl-block">

                                <QRCodesDesktop
                                    urlPath={urlPath}
                                    apiPath={apiPath}
                                    getLinks={getLinks}
                                    navigate={navigate}
                                    hasActiveFilters={hasActiveFilters}
                                    setActiveFilters={setActiveFilters}
                                    links={links}
                                    downloadQR={downloadQR}
                                    deleteLinkConfirm={deleteLinkConfirm}

                                    filters={filters}
                                    divisions={divisions}
                                    users={users}
                                    updateFilters={updateFilters}
                                    activeFilters={activeFilters}
                                    activeQueryString={activeQueryString}
                                />

                            </div>

                        </div>


                        <div className="card-footer">
                            <CustomPagination
                                data={links}
                                apiPath={apiPath}
                                pageInfo={paginationInfo}
                                getFunction={getLinks}
                            />
                        </div>

                    </div>

                    : <div className="alert alert-warning">You have no QR codes.</div>
            }

            


        </Container>
    );
}

export default QRCodes;
