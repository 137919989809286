import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const AuthReducer = (state, action) => {

  const SwAlert = withReactContent(Swal);

  const message = (title, text, icon = "error", toast= false) => {
    SwAlert.fire({
            title,
            text,
            icon,
            toast: toast,
            position: 'center',
            showConfirmButton: true
        })
  }
  switch (action.type) {

    case "LOGIN_SUCCESS":
      localStorage.setItem('user', JSON.stringify(action.payload));
      return action.payload;

    case "LOGIN_FAILED":
      message("Error", action.payload)
      return state;

    case "LOGOUT":
      localStorage.removeItem('user');
      SwAlert.fire({
        title : "You've been logged out successfully.",
        icon : "success",
        toast: true,
        timer: 1500,
        position: 'top-right',
        showConfirmButton: false
      })
      return null;
    
    case "REDIRECT_TO_LOGIN":
      localStorage.removeItem('user');
      console.log('Logout Action')
      return null;

    default:
      message("Error", "Something went wrong. That action is not permitted.")
      return state
  }
  }
  
  export default AuthReducer