import React, { useContext, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Eye } from 'react-feather';
import { useLocation } from 'react-router-dom';
import API from '../../API/API';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { PageContext } from '../../context/pageContext';
import { labelHTML, removeError } from '../../utils/formUtils';
import { appThemes, showConfirmation, showToast } from '../../utils/utils';
import { isValidPassword } from '../../utils/validationUtils';

import './Profile.css';

function Profile() {

    const { setPageInfo } = useContext(PageContext);
    const { showLoader, setShowLoader } = useContext(LoaderContext);
    const { authUser, authDispatch } = useContext(AuthContext);
    const [tempUser, setTempUser] = useState({ email: '', firstname: '', lastname: '', currentPassword: '', password: '', password_confirmation: '', avatar: '' });
    const [showPassword, setShowPassword] = useState(false);

    const [errors, setErrors] = useState({});
    const [divisions, setDivisions] = useState([]);

    const loc = useLocation();

    const getDivisions = () => {

        if (!showLoader) setShowLoader(true);
        API.get('divisions')
            .then((res) => {
                const ds = res.data.content;
                setDivisions(ds);
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
            });
    }

    useEffect(() => {
        getDivisions();

        setPageInfo({ title: "My Profile", path: loc.pathname, hasCreate: false });
        setTempUser({ email: authUser.email, firstname: authUser.firstname, lastname: authUser.lastname, divisionId: authUser.division.id, currentPassword: '', password: '', password_confirmation: '', avatar: authUser.avatar ? authUser.avatar : '' });

    }, []);

    const updateProfile = (e) => {
        e.preventDefault();
        if (Object.keys(errors).length) {

            showConfirmation({ title: 'Errors', text: `There are one or more errors that need fixing before you can update your profile.` });
        }
        else {
            setShowLoader(true);
            API.put(`user/update/${authUser.id}`, tempUser)
                .then((res) => {

                    if (res.status === 200) {
                        const newData = res.data.content;
                        const userInfo = {
                            avatar: newData.avatar,
                            avatar2: authUser.avatar2,
                            division: newData.division,
                            email: newData.email,
                            firstname: newData.firstname,
                            id: authUser.id,
                            isAdmin: newData.is_admin,
                            lastname: newData.lastname,
                            token: newData.api_token ? newData.api_token : authUser.token,
                            theme: newData.theme || null
                        }

                        authDispatch({ type: "LOGIN_SUCCESS", payload: userInfo });

                        showToast({ title: 'Your profile was updated.' });
                        setTempUser({ ...userInfo, currentPassword: '', password: '', password_confirmation: '' })
                    }

                    setShowLoader(false);
                })
                .catch((err) => {
                    console.log(err)
                    setShowLoader(false);
                });
        }
    }

    const getThemeOptions = () => {
        let themes = [...appThemes].filter(t => (t.id !== 'default')).sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0).map((a, pos) => (
            <option key={`theme-${pos}`} value={a.id}>{a.name}</option>
        ))
        return themes;
    }

    return (
        <Container fluid>
            <div className="row mb-5">
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-heading">Your Profile Details</h5>
                        </div>
                        <div className="card-body">

                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="row user-profile-image">
                                        <div className="col-12 mb-3 col-md-3 text-center text-lg-start">
                                            <img src={authUser.avatar ? authUser.avatar :
                                                authUser.avatar2} alt="" />
                                        </div>
                                        <div className="col-12 mb-3 col-md-9">

                                            <div className="row">

                                                <div className="col-12 mb-3">
                                                    {labelHTML("Profile Picture", 'avatar', true, 'profile-avatar', errors)}
                                                    <input
                                                        id="profile-avatar"
                                                        type="text"
                                                        placeholder="Image URL"
                                                        value={tempUser.avatar}
                                                        className={`form-control ${errors.avatar && errors.avatar.length ? 'is-invalid' : ''}`}
                                                        onChange={(e) => {

                                                            setTempUser({ ...tempUser, avatar: e.target.value })
                                                        }}
                                                        onBlur={(e) => {
                                                            removeError('avatar', errors, setErrors);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 text-end">
                                                    {
                                                        authUser.avatar &&
                                                        <Button variant="danger" size="sm" className="me-2" onClick={() => {
                                                            setTempUser({ ...tempUser, avatar: '' })
                                                        }}>Remove Image</Button>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("Email Address", 'email', true, 'profile-email', errors)}
                                    <input
                                        id="profile-email"
                                        type="text"
                                        value={tempUser.email}
                                        required
                                        className={`form-control ${errors.email && errors.email.length ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            setTempUser({ ...tempUser, email: e.target.value })
                                        }}
                                        onBlur={(e) => {
                                            removeError('email', errors, setErrors);

                                            if (!e.target.value.length) {
                                                setErrors({ ...errors, email: "Please enter your email address." })
                                            }
                                        }}
                                    />
                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("Division", 'divisionId', true, 'user-division', '', errors)}

                                    <select
                                        id="user-division"
                                        value={tempUser.divisionId || authUser.division.id}
                                        className={`form-select  ${errors.divisionId && errors.divisionId.length ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            removeError('divisionId', errors, setErrors);
                                            if (!e.target.value || !e.target.value.length) {
                                                setErrors({ ...errors, divisionId: "Please select your division." })
                                            }
                                            else {
                                                setTempUser({ ...tempUser, divisionId: e.target.value })
                                            }
                                        }}
                                        required
                                    >
                                        {
                                            divisions ?
                                                divisions.map((d, pos) => (
                                                    <option key={`division-${pos}`} value={d.id}>{d.name}</option>
                                                ))
                                                : ''
                                        }

                                    </select>
                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("First Name", 'firstname', true, "profile-firstname", '', errors)}
                                    <input
                                        id="profile-firstname"
                                        type="text"
                                        value={tempUser.firstname}
                                        required
                                        className={`form-control ${errors.firstname && errors.firstname.length ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            setTempUser({ ...tempUser, firstname: e.target.value })
                                        }}
                                        onBlur={(e) => {
                                            removeError('firstname', errors, setErrors);

                                            if (!e.target.value.length) {
                                                setErrors({ ...errors, firstname: "Please enter your first name." })
                                            }
                                        }}
                                    />
                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("Surname", 'lastname', true, "profile-lastname", '', errors)}
                                    <input
                                        id="profile-lastname"
                                        type="text"
                                        value={tempUser.lastname}
                                        required
                                        className={`form-control ${errors.lastname && errors.lastname.length ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            setTempUser({ ...tempUser, lastname: e.target.value })
                                        }}
                                        onBlur={(e) => {
                                            removeError('lastname', errors, setErrors);

                                            if (!e.target.value.length) {
                                                setErrors({ ...errors, lastname: "Please enter your surname." })
                                            }
                                        }}
                                    />
                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("Theme", 'theme', true, 'user-theme', '', errors)}

                                    <select
                                        id="user-theme"
                                        value={tempUser.theme || authUser.theme || 'default'}
                                        className={`form-select  ${errors.theme && errors.theme.length ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            removeError('theme', errors, setErrors);
                                            if (!e.target.value || !e.target.value.length) {
                                                setErrors({ ...errors, theme: "Please select your division." })
                                            }
                                            else {
                                                setTempUser({ ...tempUser, theme: e.target.value })
                                            }
                                        }}
                                        required
                                    >
                                        <option key={`theme-default`} value="default">Default</option>
                                        {
                                            getThemeOptions()
                                        }

                                    </select>
                                </div>

                            </div>


                            <hr />


                            <h6>Update Password</h6>
                            <p style={{ fontSize: '0.8em' }}>Leave blank to keep your current password.</p>
                            <div className="row">

                                <div className="col-12 mb-3">
                                    {labelHTML("Current Password", 'currentPassword', true, "profile-password-current", '', errors)}
                                    <div className="input-group" style={{ flexWrap: "nowrap" }}>
                                        <input
                                            id="profile-password-current"
                                            type={showPassword ? "text" : "password"}
                                            value={tempUser.currentPassword}
                                            required
                                            className={`form-control ${errors.currentPassword && errors.currentPassword.length ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setTempUser({ ...tempUser, currentPassword: e.target.value })
                                            }}
                                        />
                                        <span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}><Eye size="1em" /></span>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("New Password", 'password', true, "profile-password-new", '', errors)}
                                    <div className="input-group" style={{ flexWrap: "nowrap" }}>

                                        <input
                                            id="profile-password-new"
                                            type={showPassword ? "text" : "password"}
                                            value={tempUser.password}
                                            required
                                            className={`form-control ${errors.password && errors.password.length ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setTempUser({ ...tempUser, password: e.target.value })
                                            }}
                                            onBlur={(e) => {
                                                removeError('password', errors, setErrors);
                                                removeError('password_confirmation', errors, setErrors);

                                                if (e.target.value.length) {

                                                    if (!isValidPassword(e.target.value)) {
                                                        setErrors({ ...errors, password: "Your password must be a minium of 8 caharacters, contain lowercase letters, uppercase letters and numbers." })
                                                    }
                                                    else if (tempUser.password_confirmation.length && e.target.value !== tempUser.password_confirmation) {
                                                        setErrors({ ...errors, password_confirmation: "Password and password confirmation don't match." })
                                                    }

                                                }
                                            }}
                                        />
                                        <span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}><Eye size="1em" /></span>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("Confirm Password", 'password_confirmation', true, "profile-password-confirm", '', errors)}
                                    <div className="input-group" style={{ flexWrap: "nowrap" }}>
                                        <input
                                            id="profile-password-confirm"
                                            type={showPassword ? "text" : "password"}
                                            value={tempUser.password_confirmation}
                                            required
                                            className={`form-control ${errors.password_confirmation && errors.password_confirmation.length ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setTempUser({ ...tempUser, password_confirmation: e.target.value })
                                            }}

                                            onBlur={(e) => {
                                                removeError('password_confirmation', errors, setErrors);

                                                if (
                                                    (tempUser.password.length && e.target.value !== tempUser.password)
                                                    || (!tempUser.password.length && e.target.value.length)
                                                ) {
                                                    setErrors({ ...errors, password_confirmation: "Password and password confirmation don't match." })
                                                }

                                            }
                                            }
                                        />
                                        <span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}><Eye size="1em" /></span>
                                    </div>
                                </div>

                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-12 text-end">
                                    <Button variant="success" size="sm" onClick={updateProfile}>Save Profile</Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </Container>
    );
}

export default Profile;
