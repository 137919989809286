import { useContext } from 'react';
import { BrowserRouter as Router, Routes as Switch, Route, Navigate } from 'react-router-dom';

import { AuthContext } from './context/authContext/AuthContext';
import { PageContextProvider } from './context/pageContext';

import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import Loader from './components/Loader/Loader';
import Page404 from './pages/Page404/Page404';

import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import MyLinks from './pages/Links/MyLinks';
import ShowLink from './pages/Links/ShowLink/ShowLink';
import AllLinks from './pages/Links/AllLinks';
import CreateLinks from './pages/Links/CreateLinks';
import CreateLinksSuccess from './pages/Links/CreateLinksSuccess';
import EditLink from './pages/Links/EditLink';
import Users from './pages/Users/Users';
import CreateUser from './pages/Users/CreateUser';
import EditUser from './pages/Users/EditUser';
import { Offcanvas } from 'react-bootstrap';
import { SidebarContext } from './context/sidebarContext';
import AllQRCodes from './pages/QRCodes/AllQRCodes';

import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './root-colors.css';
import './bootstrap-overrides.css';
import './App.css';

// Themes
import './css-themes/App.Dark.css';
import ShowQRCode from './pages/QRCodes/ShowQRCode/ShowQRCode';
import EditQRCode from './pages/QRCodes/EditQRCode';
import MyQRCodes from './pages/QRCodes/MyQRCodes';
import CreateQRCodes from './pages/QRCodes/CreateQRCodes';
import CreateQRCodesSuccess from './pages/QRCodes/CreateQRCodesSuccess';

function App() {

  const { authUser } = useContext(AuthContext);
  const { sidebarIsOpen, setSidebarIsOpen } = useContext(SidebarContext);

  return (
    <div className={`App ${authUser && (authUser.theme || '')}`}>
      <Loader />
      <Router>
        {
          !authUser ?
            <Login />
            :
            <PageContextProvider>
              <main className="container-fluid main">
                <div className="row page-wrapper">
                  <div className={`sidebar d-none d-xl-block ${authUser && (authUser.theme || '')}`}>
                    <Sidebar />
                  </div>


                  <Offcanvas
                    show={sidebarIsOpen}
                    className={`d-block d-xl-none sidebar-canvas ${authUser && (authUser.theme || '')}`}
                    backdropClassName="d-block d-xl-none"
                    onEscapeKeyDown={() => setSidebarIsOpen(false)}
                    onHide={() => setSidebarIsOpen(false)}
                  >
                    <Offcanvas.Body>
                      <div className={`sidebar d-block d-xl-none ${authUser && (authUser.theme || '')}`}>
                        <Sidebar />
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>


                  <div className="page-content">
                    <div className="page-header">
                      <Header />
                    </div>
                    <Switch>

                      <Route exact path="/" element={<Navigate replace to="/dashboard" />} />
                      <Route exact path="/login" element={<Navigate replace to="/dashboard" />} />
                      <Route exact path="/dashboard" element={<Dashboard />} />

                      <Route exact path="/links/mine" element={<MyLinks />} />
                      <Route exact path="/links/all" element={<AllLinks />} />
                      <Route exact path="/link/:id" element={<ShowLink />} />
                      <Route exact path="/link/edit/:id" element={<EditLink />} />
                      <Route exact path="/links/create" element={<CreateLinks />} />
                      <Route exact path="/links/create/success" element={<CreateLinksSuccess />} />

                      <Route exact path="/qr-codes/mine" element={<MyQRCodes />} />
                      <Route exact path="/qr-codes/all" element={<AllQRCodes />} />
                      <Route exact path="/qr-code/:id" element={<ShowQRCode />} />
                      <Route exact path="/qr-code/edit/:id" element={<EditQRCode />} />
                      <Route exact path="/qr-codes/create" element={<CreateQRCodes />} />
                      <Route exact path="/qr-codes/create/success" element={<CreateQRCodesSuccess />} />


                      <Route exact path="/profile" element={<Profile />} />
                      <Route exact path="/users" element={<Users />} />
                      <Route exact path="/users/create" element={<CreateUser />} />
                      <Route exact path="/user/edit/:id" element={<EditUser />} />
                      <Route path="*" element={<Page404 />} />

                    </Switch>
                  </div>

                </div>
              </main>
            </PageContextProvider>

        }

      </Router>

    </div>
  );
}

export default App;
