import React, { useContext } from 'react';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { isDarkTheme } from '../../utils/utils';

import "./Loader.css";


function Loader() {

  const {showLoader} = useContext(LoaderContext);
  const {authUser} = useContext(AuthContext);

  const html = showLoader ? <div className={`page-loader ${isDarkTheme(authUser?.theme) ? 'dark' : ''}`}>
    <div className="lds-ripple"><div></div><div></div></div>
  </div> : '';

  return html;
}

export default Loader;
