import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { CheckCircle } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API/API';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { PageContext } from '../../context/pageContext';
import { copyToClipboard, downloadFile, generateCSV, isDarkTheme, showToast } from '../../utils/utils';

import './Links.css';

function CreateLinksSuccess() {

    const { setShowLoader } = useContext(LoaderContext);
    const { setPageInfo } = useContext(PageContext);
    const [divisions, setDivisions] = useState([]);
    const [links, setLinks] = useState([]);
    const { authUser } = useContext(AuthContext);

    const navigate = useNavigate();
    const loc = useLocation();

    useEffect(() => {

        if (!loc.state.links || !loc.state.links.length) navigate('/dashboard');

        setPageInfo({ title: "Links Created", path: loc.pathname, hasCreate: false });
        setLinks(loc.state.links);

        setShowLoader(true);

        API.get('divisions')
            .then(res => {
                setDivisions(res.data.content);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err.response)
                setShowLoader(false)
            })
    }, [])


    const copyLink = (key) => {
        const t = showToast('Short Link Copied', "");
        copyToClipboard(`https://mrp.bz/${key}`, t);
    }

    const download = () => {

        const preppedLinks = links.map(l => {
            let row = {};

            row.id = l.id;
            row.title = l.title;
            row.long_url = l.long_url;
            row.short_url = l.short_url;
            row.division = l.division.name;
            row.tags = l.tags.join(' - ');
            row.utm_source = l.utm_source;
            row.utm_medium = l.utm_medium;
            row.utm_campaign = l.utm_campaign;
            return row;

        });

        const date = new Date();
        const y = date.getFullYear();
        const m = ("0" + (date.getMonth() + 1)).slice(-2);
        const d = ("0" + date.getDate()).slice(-2);
        const t = ("0" + date.getHours()).slice(-2) + ("0" + date.getMinutes()).slice(-2)

        const filename = `shortlinks-${y}${m}${d}-${t}`;
        const csv = generateCSV(preppedLinks);
        downloadFile(csv, filename);
    }

    return (
        <Container fluid>

            <div className="row mb-3">
                <div className="col-12">
                    <div className="alert alert-success d-flex justify-content-between">
                        <span>
                            <CheckCircle /> Your links were created successfully.
                        </span>
                        <Button variant="info" size="sm" className="ms-auto" onClick={download}>Export CSV</Button>
                    </div>
                </div>
            </div>

            <Table striped bordered hover className="links-table">
                <thead>
                    <tr>
                        <th className="link-title">Title</th>
                        <th className="division-logo">Division</th>
                        <th className="long-url">Long URL</th>
                        <th className="short_url" colSpan="2">Short URL</th>
                        <th className="tags">Tags</th>
                        <th className="utm_source">UTM Source</th>
                        <th className="utm_medium">UTM Medium</th>
                        <th className="utm_campaign">UTM Campaign</th>
                        <th className="privacy">Privacy</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        links && links.length ?
                            links.map((l) => {
                                return (
                                    <tr key={l.id}>
                                        <td className="link-title">{l.title}</td>
                                        <td className="division-logo">
                                            {
                                                l.division.logo && <img src={isDarkTheme(authUser.theme) ? l.division.logo_alt : l.division.logo} alt={l.division.name} />
                                            }
                                        </td>

                                        <td className="long-url">

                                            <OverlayTrigger

                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`longUrlTooltip-${l.id}`}>
                                                        {l.long_url}
                                                    </Tooltip>
                                                }
                                            >
                                                <a href={l.long_url} target="_blank">{l.long_url.replace('https://www.', '').replace('http://www.', '').replace('https://', '').replace('http://', '')}</a>

                                            </OverlayTrigger>

                                        </td>
                                        <td className="short-url">{l.short_url}</td>
                                        <td className="short-url-copy">
                                            <div className="row">
                                                <div className="col-auto">

                                                </div>
                                                <div className="col-auto">

                                                </div>
                                            </div>
                                            <Button variant="success" size="sm" onClick={(e) => {
                                                e.preventDefault();
                                                copyLink(l.short_url)
                                            }}>
                                                copy
                                            </Button>
                                        </td>
                                        <td className="tags">
                                            {
                                                l.tags.length ?
                                                    l.tags.map((t, pos) => {
                                                        return (
                                                            <div key={`${t}-${pos}`} className="badge rounded-pill bg-primary me-2 mb-1">{t}</div>
                                                        )
                                                    }) : ''
                                            }
                                        </td>
                                        <td className="utm_source">{l.utm_source}</td>
                                        <td className="utm_medium">{l.utm_medium}</td>
                                        <td className="utm_campaign">{l.utm_campaign}</td>
                                        <td className="privacy">
                                            {
                                                l.privacy === 'user' ? 'Private' :
                                                    l.privacy === 'group' ? "Group"
                                                        : 'None'
                                            }
                                        </td>
                                    </tr>
                                )
                            }) : null
                    }
                </tbody>

            </Table>
        </Container>
    )
}

export default CreateLinksSuccess;
