import React, { useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { PageContext } from '../../context/pageContext';

import QRCodes from './QRCodes';

function MyQRCodes() {

    const { setPageInfo } = useContext(PageContext);

    const loc = useLocation();

    useEffect(() => {
        setPageInfo({ title: "My QR Codes", path: loc.pathname, hasCreate: true, createURL: 'qr-codes/create' });
    }, [])


    return (
        <QRCodes apiPath="/my-qr-codes" urlPath="/qr-codes/mine" linkFilters={['divisions', 'search']} />
    );
}

export default MyQRCodes;
