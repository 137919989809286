import React, { useContext, useEffect } from 'react'
import { Button, Container, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { PageContext } from '../../context/pageContext';
import { Menu, Plus } from 'react-feather';

import './Header.css';
import { SidebarContext } from '../../context/sidebarContext';

function Header() {

    const {pageInfo} = useContext(PageContext);
    const{sidebarIsOpen, setSidebarIsOpen} = useContext(SidebarContext);
    

    useEffect( () => {
        document.title = pageInfo.title && typeof pageInfo.title === 'string' ? `${pageInfo.title} | MRP Short Link Creator` : `MRP Short Link Creator`;
    }, [pageInfo])

    return (
        <Navbar className="header-nav">
            <Container fluid>
            <Menu 
                    className="d-xl-none me-4 cursor-pointer" 
                    style={{marginTop: "-0.2em"}} 
                    size={36}
                    onClick={
                        () => {
                            setSidebarIsOpen(!sidebarIsOpen)
                        }
                    }
                    /> 
                <Navbar.Brand style={{textTransform: 'uppercase'}} >
                    {pageInfo.title}</Navbar.Brand>
                
                {
                    pageInfo.hasCreate && 
                        <Navbar.Collapse className="justify-content-end">
                        
                        <Navbar.Text>
                            <Button variant="primary" size="sm" as={Link} to={pageInfo.hasCreate && pageInfo.createURL ? pageInfo.createURL : ''} className="d-flex align-items-center"><Plus color="#FFF" size={16} /><span className="d-none d-lg-inline ms-1">Create</span></Button>
                        </Navbar.Text>

                        </Navbar.Collapse>
                }
                
            </Container>
        </Navbar>
    )
}

export default Header
