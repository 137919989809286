import React, { useState } from 'react';

import API from '../../API/API';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TagSelector from '../../components/TagSelector/TagSelector';
import { HelpCircle } from 'react-feather';
import { labelHTML, removeError } from '../../utils/formUtils';
import { isValidContactLink, isValidURL } from '../../utils/validationUtils';

import qrPlaceholder from './qr-placeholder.jpg';

import noIcon from './QRIcons/noicon.png';
import CSSSpinner from '../../components/CSSSpinner/CSSSpinner';
import { QRIcons } from './QRIcons/QRIcons';

function CreateQRCodeRow({ rowID, rows, setRows, divisions, tagSuggestions, setTagSuggestions, rowPosition, rowLinkProps, addRow, updateRow, isFetchingRowData, setIsFetchingRowData, getIconByName, showLoader }) {

    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingQR, setIsFetchingQR] = useState(false);
    const [previewImg, setPreviewImg] = useState({ url: null, img: null });

    const setErrors = (value) => {
        updateRow(rowPosition, 'errors', value);
    }

    const updateQRMeta = (url) => {
        if (isValidURL(url)) {
            setIsFetching(true);
            setIsFetchingRowData(true);
            setIsFetchingQR(true);

            API.post('/qr/get-url-meta', { url: url, eye_color: rowLinkProps.eye_color })
                .then((res) => {

                    const defaults = res.data.content;

                    if (defaults) {
                        if (defaults.destination) {
                            updateRow(rowPosition, 'destination', defaults.destination);
                        }

                        if (!rowLinkProps.title.length && defaults.title) {
                            updateRow(rowPosition, 'title', defaults.title);
                            removeError('title', rowLinkProps.errors, setErrors)
                        }

                        if (!rowLinkProps.utm_campaign.length && defaults.utm_campaign) {
                            updateRow(rowPosition, 'utm_campaign', defaults.utm_campaign);
                            removeError('utm_campaign', rowLinkProps.errors, setErrors)
                        }

                        if (defaults.previewImg) {
                            setPreviewImg({ url: url, img: defaults.previewImg });
                        }
                    }

                    setIsFetchingQR(false);
                    setIsFetching(false);
                    setIsFetchingRowData(false);
                })
                .catch((err) => {
                    setIsFetching(false);
                    setIsFetchingQR(false);
                    setIsFetchingRowData(false);
                    console.log(err)
                })
        }
    }

    const getLinkDefaults = (e) => {

        const url = e.target.value;

        if (!url.length) {
            setErrors({ ...rowLinkProps.errors, destination: "Please enter a URL. eg: https://mrpmoney.com or https://www.mrphome.com" })
        }
        else {
            removeError('destination', rowLinkProps.errors, setErrors)
        }

        if (!isValidURL(url) && !isValidContactLink(url)) {
            // Show Error Message
            setErrors({ ...rowLinkProps.errors, destination: "Please enter a valid URL. eg: https://mrpmoney.com or https://www.mrphome.com" })
            setPreviewImg({ url: null, img: null });
            return;
        }

        updateQRMeta(url);

    }

    const sanitizeUTM = (value, field) => {
        let newValue = value.trim().replaceAll(" ", '_');
        updateRow(rowPosition, field, newValue);
    }


    // HANDLE TAGS
    const removeTag = (i) => {
        updateRow(rowPosition, 'tags', rowLinkProps.tags.filter((tag, index) => index !== i));
    }

    const clearTags = () => {
        updateRow(rowPosition, 'tags', [])
    }

    const addTag = (tag) => {

        updateRow(rowPosition, 'tags', [...rowLinkProps.tags, tag]);

        // update suggestions
        const newTagI = tagSuggestions.findIndex((i) => i.name.toLowerCase() == tag.name.toLowerCase());
        if (newTagI < 0) {
            setTagSuggestions([...tagSuggestions, tag]);
        }

    };

    const updateTags = (i, newTag) => {
        const updatedTags = rowLinkProps.tags.slice();
        updatedTags.splice(i, 1, newTag);
        // setTags(updatedTags);
        updateRow(rowPosition, 'tags', updatedTags)
    };

    const updateLinkProps = (e, field) => {
        updateRow(rowPosition, field, e.target.value);
    }

    const updateIcon = (name) => {
        const x = getIconByName(name);

        updateRow(rowPosition, 'icon', x.icon);
        updateRow(rowPosition, 'icon_name', name);
        updateRow(rowPosition, 'eye_color', x.color);
        updateQRMeta(rowLinkProps.destination);
    }

    const removeIcon = () => {
        updateRow(rowPosition, 'icon', null);
        updateRow(rowPosition, 'icon_name', null);
        updateRow(rowPosition, 'eye_color', '0,0,0,0,0,0');
    }


    return (
        <>
            <div className="new-link-row d-flex">

                {
                    rows.length > 1 &&
                    <div className="link-num">
                        <div className="vr"></div>
                        <div className={`badge rounded-pill ${Object.keys(rowLinkProps.errors).length ? 'bg-danger' : 'bg-success'} digit`}>{rowPosition + 1}</div>
                        <div className="vr"></div>
                    </div>
                }

                <div className="link-details">

                    <div className="row mb-3">

                        <div className="col-md-6 mb-2 d-flex flex-column flex">

                            <div className="card add-link-card-required h-100 mb-3">
                                <div className="card-header">
                                    <h5 className="m-0">Required Fields</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-3 mb-3">
                                            <label>Preview</label>
                                            <div className="preview-qr">

                                                {
                                                    !previewImg.img ?
                                                        <>
                                                            {
                                                                isFetchingQR ? <CSSSpinner className="qr-spinner" /> : ''
                                                            }

                                                            <img src={qrPlaceholder} alt="Preview QR code" className="qr-background-image" />
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                isFetchingQR ? <CSSSpinner className="qr-spinner" /> : ''
                                                            }

                                                            {/* {
                                                                rowLinkProps.icon ?
                                                                    <img src={rowLinkProps.icon} alt="foreground" className="qr-foreground-image" style={{ visibility: isFetchingQR ? 'hidden' : 'visible' }} />
                                                                    : ''
                                                            } */}

                                                            <img src={previewImg.img} alt="Preview QR code" className="qr-background-image" />
                                                        </>

                                                }

                                            </div>

                                        </div>
                                        <div className="col-12 col-md-9 mb-3">

                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    {labelHTML('Destination URL', 'destination', true, `longUrl-${rowID}`, '', rowLinkProps.errors)}

                                                    <textarea
                                                        id={`longUrl-${rowID}`}
                                                        className={`form-control ${rowLinkProps.errors.destination && rowLinkProps.errors.destination.length ? 'is-invalid' : ''}`}
                                                        value={rowLinkProps.destination}
                                                        onBlur={getLinkDefaults}
                                                        onChange={e => updateLinkProps(e, 'destination')}
                                                        required />

                                                </div>
                                            </div>

                                            {/* <div className="row">
                                                <div className="col-12">
                                                    <label>Icon</label>
                                                    <div className="row">
                                                        {
                                                            QRIcons.map((i, pos) => (
                                                                <div className="col-4 col-md-2 mb-2" key={`icon-${pos}`}>
                                                                    <div className={`icon-border cursor-pointer ${rowLinkProps.icon === i.icon ? 'icon-selected' : ''}`} title={i.alt} onClick={() => {
                                                                        updateIcon(i.name);
                                                                    }}>
                                                                        <img src={i.icon} alt={i.alt} />
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }

                                                        <div className="col-4 col-md-2  mb-2">
                                                            <div className={`icon-border cursor-pointer ${rowLinkProps.icon === null ? 'icon-selected' : ''}`} title="Remove icon" onClick={removeIcon}>
                                                                <img src={noIcon} alt="No Icon" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> */}


                                        </div>


                                    </div>
                                    <div className="row">

                                        <div className="col-sm-12 mb-3">

                                            {labelHTML('Link Title', 'title', true, rowID, '', rowLinkProps.errors)}

                                            <input
                                                id={`title-${rowID}`}
                                                placeholder={isFetching ? "Checking..." : ''}
                                                className={`form-control ${rowLinkProps.errors.title && rowLinkProps.errors.title.length ? 'is-invalid' : ''}`}
                                                type="text"
                                                value={rowLinkProps.title}
                                                onBlur={(e) => {
                                                    if (!e.target.value) setErrors({ ...rowLinkProps.errors, title: "Please enter a title." })
                                                    else {
                                                        removeError('title', rowLinkProps.errors, setErrors);
                                                    }
                                                }}
                                                onChange={e => updateLinkProps(e, 'title')}
                                                disabled={isFetching && !rowLinkProps.title.length}
                                                required />

                                        </div>

                                        <div className="col-md-6 mb-3">

                                            {labelHTML('Division', 'division', true, rowID, '', rowLinkProps.errors)}

                                            <select
                                                id={`division-${rowID}`}
                                                value={rowLinkProps.division}
                                                className={`form-select  ${rowLinkProps.errors.division && rowLinkProps.errors.division.length ? 'is-invalid' : ''}`}
                                                onChange={e => updateLinkProps(e, 'division')}
                                                required
                                            >
                                                {
                                                    divisions &&
                                                    divisions.map((d, pos) => (
                                                        <option key={`d-${rowID}-${pos}`} value={d.id}>{d.name}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>

                                        <div className="col-md-6 mb-3">

                                            {labelHTML('Privacy', 'privacy', true, rowID, '', rowLinkProps.errors)}

                                            <select
                                                id={`privacy-${rowID}`}
                                                defaultValue="none"
                                                className={`form-select ${rowLinkProps.errors.privacy && rowLinkProps.errors.privacy.length ? 'is-invalid' : ''}`}
                                                onChange={e => updateLinkProps(e, 'privacy')}
                                                required
                                            >
                                                <option value="none">None</option>
                                                <option value="division">Division</option>
                                                <option value="user">Me Only</option>

                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-6 mb-2 d-flex flex-column">
                            <div className="card add-link-card-required flex">
                                <div className="card-header">
                                    <h5 className="m-0">Optional Fields</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 mb-4">

                                            {labelHTML('Tags', 'tags', false, rowID, '', rowLinkProps.errors)}

                                            <TagSelector
                                                options={{
                                                    id: `tags-${rowID}`,
                                                    placeholder: "Add Tags",
                                                    clearAll: true,
                                                    allowUnique: true,
                                                    inline: true,
                                                    allowAdditionFromPaste: true,
                                                    editable: true,
                                                    classNames: {
                                                        tagInputField: rowLinkProps.errors.tags && rowLinkProps.errors.tags.length ? 'is-invalid' : '',
                                                    },
                                                    onTagUpdate: updateTags,
                                                    onClearAll: clearTags,
                                                    handleAddition: addTag,
                                                    handleDelete: removeTag

                                                }}
                                                tags={rowLinkProps.tags}
                                                suggestions={tagSuggestions} />

                                        </div>

                                        <div className="col-12 mb-1 d-flex">
                                            <h5 className="mb-0">UTM Parameters</h5>
                                            <OverlayTrigger
                                                placement="top"
                                                trigger="click"
                                                overlay={
                                                    <Tooltip id={`utm-explain-${rowID}`}>
                                                        UTMs can help you track web traffic in analytics tools.
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="ms-1 d-block cursor-pointer"><HelpCircle size="1em" /></span>
                                            </OverlayTrigger>
                                        </div>

                                        <div className="col-12 mb-3">

                                            {labelHTML('Campaign', 'utm_campaign', false, rowID, '', rowLinkProps.errors)}

                                            <input
                                                id={`utm_campaign-${rowID}`}
                                                className={`form-control  ${rowLinkProps.errors.utm_campaign && rowLinkProps.errors.utm_campaign.length ? 'is-invalid' : ''}`}
                                                type="text"
                                                value={rowLinkProps.utm_campaign}
                                                onChange={e => updateLinkProps(e, 'utm_campaign')}
                                                onBlur={(e) => sanitizeUTM(e.target.value, 'utm_campaign')}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-12 mt-3 text-end">
                            {
                                <>
                                    {
                                        rows.length > 1 &&
                                        <Button
                                            variant="danger"
                                            size="sm" className="me-4"
                                            disabled={showLoader || isFetchingRowData}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setRows(() => {
                                                    const rowsCopy = Array.from(rows).filter((r) => {
                                                        return r.id !== rowID;
                                                    })

                                                    return rowsCopy;
                                                })
                                            }}>Remove</Button>
                                    }
                                    {
                                        rowPosition + 1 === rows.length &&
                                        <Button
                                            variant="info"
                                            size="sm"
                                            disabled={showLoader || isFetchingRowData}
                                            onClick={() => {

                                                let errorsCopy = { ...rowLinkProps.errors };

                                                if (!rowLinkProps.destination || !rowLinkProps.destination.length) {
                                                    errorsCopy.destination = "Please enter a URL. eg: https://mrpmoney.com or https://www.mrphome.com";
                                                }

                                                if (!rowLinkProps.title || !rowLinkProps.title.length) {
                                                    errorsCopy.title = "Please enter a title.";
                                                }

                                                setErrors(errorsCopy);

                                                addRow();
                                            }}>Add Another Link</Button>
                                    }

                                </>
                            }
                        </div>

                    </div>
                </div>
            </div>

            <hr />
        </>
    );
}

export default CreateQRCodeRow;
