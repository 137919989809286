import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { Eye } from 'react-feather';

import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API/API';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';

import { PageContext } from '../../context/pageContext';
import { labelHTML, removeError } from '../../utils/formUtils';
import { objIsEmpty } from '../../utils/objectUtils';
import { showConfirmation } from '../../utils/utils';
import { isValidPassword } from '../../utils/validationUtils';

function CreateUser() {

    const { authUser } = useContext(AuthContext);
    const { setPageInfo } = useContext(PageContext);
    const { showLoader, setShowLoader } = useContext(LoaderContext);
    const [divisions, setDivisions] = useState([]);

    const [user, setUser] = useState({ isAdmin: false });
    const [showPassword, setShowPassword] = useState(false);

    const [errors, setErrors] = useState({});

    const loc = useLocation();
    const nav = useNavigate();

    const getDivisions = () => {

        if (!showLoader) setShowLoader(true);
        API.get('divisions')
            .then((res) => {
                setDivisions(res.data.content);
                setUser({ ...user, divisionId: authUser.division.id || '1' });
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
            });
    }

    useEffect(() => {
        setPageInfo({ title: "Create User", path: loc.pathname, hasCreate: false });
        getDivisions();

    }, [])


    const hasErrors = () => {
        if (!objIsEmpty(errors)) return true;
        return false;
    }

    const submitNewUser = () => {

        if (hasErrors()) {

            showConfirmation({ title: 'Errors', text: `There are one or more errors that need fixing before you can create the user.` });
        }
        else {
            setShowLoader(true);

            API.post('/user/create', user)
                .then((res) => {

                    setErrors({});
                    setShowLoader(false);

                    if (res.response && res.response.data && res.response.data.errors && res.response.data.errors) {

                        const ers = res.response.data.errors;
                        const labels = Object.keys(ers);
                        let data = {};

                        labels.forEach((x) => {
                            data[x] = ers[x][0];
                        });

                        setErrors(data);

                    }
                    else if (res.data && res.data.content) {
                        const newUser = res.data.content;

                        nav('/users', {
                            state: {
                                newUserCreated: newUser,
                            }
                        });

                    }

                })
                .catch((err) => {
                    console.log(err);
                    setShowLoader(false);
                });

        }

    }

    return (
        <Container fluid>
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("Email Address", 'email', true, 'user-email', '', errors)}
                                    <input
                                        id="user-email"
                                        type="text"
                                        value={user.email || ''}
                                        required
                                        className={`form-control ${errors.email && errors.email.length ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            setUser({ ...user, email: e.target.value })
                                        }}
                                        onBlur={(e) => {
                                            removeError('email', errors, setErrors);

                                            if (!e.target.value.length) {
                                                setErrors({ ...errors, email: "Please enter the user's email address." })
                                            }
                                        }}
                                    />
                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("Division", 'divisionId', true, 'user-division', '', errors)}

                                    <select
                                        id="user-division"
                                        value={user.divisionId || 1}
                                        className={`form-select  ${errors.divisionId && errors.divisionId.length ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            removeError('divisionId', errors, setErrors);
                                            if (!e.target.value || !e.target.value.length) {
                                                setErrors({ ...errors, divisionId: "Please enter the user's division." })
                                            }
                                            else {
                                                setUser({ ...user, divisionId: e.target.value })
                                            }
                                        }}
                                        required
                                    >
                                        {
                                            divisions &&
                                            divisions.map((d, pos) => (
                                                <option key={`division-${pos}`} value={d.id}>{d.name}</option>
                                            ))
                                        }

                                    </select>
                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("First Name", 'firstname', true, "user-firstname", '', errors)}
                                    <input
                                        id="user-firstname"
                                        type="text"
                                        value={user.firstname || ''}
                                        required
                                        className={`form-control ${errors.firstname && errors.firstname.length ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            setUser({ ...user, firstname: e.target.value })
                                        }}
                                        onBlur={(e) => {
                                            removeError('firstname', errors, setErrors);

                                            if (!e.target.value.length) {
                                                setErrors({ ...errors, firstname: "Please enter the user's first name." });
                                            }
                                        }}
                                    />
                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("Surname", 'lastname', true, "user-lastname", '', errors)}
                                    <input
                                        id="user-lastname"
                                        type="text"
                                        value={user.lastname || ''}
                                        required
                                        className={`form-control ${errors.lastname && errors.lastname.length ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            setUser({ ...user, lastname: e.target.value })
                                        }}
                                        onBlur={(e) => {
                                            removeError('lastname', errors, setErrors);

                                            if (!e.target.value.length) {
                                                setErrors({ ...errors, lastname: "Please enter the user's surname." })
                                            }
                                        }}
                                    />
                                </div>

                            </div>

                            <hr />

                            <h6>Set Password</h6>

                            <div className="row">

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("New Password", 'password', true, "user-password", '', errors)}

                                    <div className="input-group" style={{ flexWrap: "nowrap" }}>
                                        <input
                                            id="profile-password-new"
                                            type={showPassword ? "text" : "password"}
                                            value={user.password || ''}
                                            required
                                            className={`form-control ${errors.password && errors.password.length ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setUser({ ...user, password: e.target.value })
                                            }}
                                            onBlur={(e) => {
                                                removeError('password', errors, setErrors);
                                                removeError('password_confirmation', errors, setErrors);

                                                if (e.target.value.length) {

                                                    if (!isValidPassword(e.target.value)) {
                                                        setErrors({ ...errors, password: "The password must be a minimum of 8 characters, contain lowercase letters, uppercase letters and numbers." })
                                                    }
                                                    else if (user.password_confirmation && user.password_confirmation.length && e.target.value !== user.password_confirmation) {
                                                        setErrors({ ...errors, password_confirmation: "Password and password confirmation don't match." })
                                                    }

                                                }
                                            }}
                                        />
                                        <span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}><Eye size="1em" /></span>
                                    </div>

                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    {labelHTML("Confirm Password", 'password_confirmation', true, "profile-password-confirm", '', errors)}
                                    <div className="input-group" style={{ flexWrap: "nowrap" }}>
                                        <input
                                            id="profile-password-confirm"
                                            type={showPassword ? "text" : "password"}
                                            value={user.password_confirmation || ''}
                                            required
                                            className={`form-control ${errors.password_confirmation && errors.password_confirmation.length ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setUser({ ...user, password_confirmation: e.target.value })
                                            }}

                                            onBlur={(e) => {
                                                removeError('password_confirmation', errors, setErrors);

                                                if (
                                                    (user.password.length && e.target.value !== user.password)
                                                    || (!user.password.length && e.target.value.length)
                                                ) {
                                                    setErrors({ ...errors, password_confirmation: "Password and password confirmation don't match." })
                                                }

                                            }
                                            }
                                        />
                                        <span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}><Eye size="1em" /></span>
                                    </div>

                                </div>

                                <div className="col-12 col-md-6 mb-3">
                                    <Form.Group className="mb-3" controlId="user-admin">
                                        <Form.Check type="checkbox" label="This user should have admin privileges."
                                            onChange={(e) => {
                                                setUser({ ...user, isAdmin: e.target.checked })
                                            }}
                                        />
                                    </Form.Group>
                                </div>

                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-12 text-end">
                                    <Button variant="success" onClick={submitNewUser}>Save Profile</Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </Container>
    );
}

export default CreateUser;
