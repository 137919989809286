export const chartLabels = (data, num) => {
    if (!num) num = Object.keys(data).length;
    const labels = Object.keys(data).slice(0,num);
    labels.sort( (a, b) => {
        return data[b] - data[a];
    });

    return labels;
}

export const themeChartColors = {
  'default' : {
    label: '#353C43',
    data: ['#C42021', '#6C0E23', '#561643', '#4464AD', '#3C1742', '#F3FFB9', '#C42021']
  },
  'dark' : {
    label: '#d9d9d9',
    data: ['#C42021', '#176BA0', '#7D3AC1', '#EABD3B', '#1AC9E6', '#3C1742', '#4464AD']
  },
}

export const chartOptions = (label = '', legendOptions, theme = 'default', otherOptions = {}) => {
    const position = legendOptions && legendOptions.position ? legendOptions.position : 'bottom';
    const display = legendOptions && legendOptions.show ? legendOptions.show : false;
    const align = legendOptions && legendOptions.align ? legendOptions.align :  'start';

    const style = themeChartColors[theme] || themeChartColors['default'];
    const labelColor = style.label || themeChartColors['default'].label;

    let options = {
        responsive: true,
        plugins: {
          legend: {
            position: position,
            display: display,
            align: align,  
            labels: {
              color: labelColor
            }          
          },
          title: {
            display: label.length ? true : false,
            text: label,
          },
        },

        scales: {
          y: {
              ticks: {
                  color: labelColor,
              }
          },
          x: {
              ticks: {
                  color: labelColor,
              }
          }
        }
    };

    if(Object.keys(otherOptions).length) {
      Object.keys(otherOptions).forEach( (o) => {
        options[o] = otherOptions[o];
      })
    }

    return options;
    
}

export const chartData = (label, labels, data, theme = 'default', colors = [], labelMapping = null) => {

    const style = themeChartColors[theme] || themeChartColors['default'];
    let dataColors;
    if(colors.length) {
      dataColors = colors;
    }
    else if (style.data) {
      dataColors = style.data;
    }
    else {
      dataColors = themeChartColors['default'].data;
    }

    return {
        
        labels: labelMapping ? labels.map( (a)  => {
                if (labelMapping[a]) return labelMapping[a].replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');
                else return a.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');
            }) : labels,
        datasets: [
            {
              label: label,
              data: labels.map( (k) => {
                return data[k];
              }),
              backgroundColor: dataColors,
              borderWidth: 1,
              borderColor: 'transparent'
            }
        ]
    }
}

export const chartDataMulti = (labels, datasets, labelMapping = null) => {
  return {
      
      labels: labelMapping ? labels.map( (a)  => {
              if (labelMapping[a]) return labelMapping[a].replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');
              else return a.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');
          }) : labels,
      datasets: datasets
  }
}