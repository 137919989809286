import React, { useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { PageContext } from '../../context/pageContext';

import Links from './Links';

function AllLinks() {

    const { setPageInfo } = useContext(PageContext);

    const loc = useLocation();

    useEffect(() => {
        setPageInfo({ title: "All Links", path: loc.pathname, hasCreate: true, createURL: 'links/create' });
    }, [])


    return (
        <Links apiPath="/links" urlPath="/links/all" linkFilters={['divisions', 'search', 'user']} />
    );
}

export default AllLinks;
