import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
  
ChartJS.register(ArcElement, Tooltip, Legend);
  
export const DonutChart = ({ chartData, options }) => {
  return (
    <div>
      <Doughnut
        data={chartData}
        options={{...options, scales: {}}}
        height={300}
      />
    </div>
  );
};