import React, { useContext, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';

import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API/API';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';

import { PageContext } from '../../context/pageContext';
import { uuid } from '../../utils/stringUtils';
import CreateQRCodeRow from './CreateQRCodeRow';
import { getIconByName, QRIcons } from './QRIcons/QRIcons';


function CreateQRCodes() {

    const { authUser } = useContext(AuthContext);
    const { setPageInfo } = useContext(PageContext);
    const { showLoader, setShowLoader } = useContext(LoaderContext);
    const [rows, setRows] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [isFetchingRowData, setIsFetchingRowData] = useState(false);

    const [tagSuggestions, setTagSuggestions] = useState([]);

    const loc = useLocation();
    const nav = useNavigate();

    const getDivisionsAndTags = () => {
        API.get('divisions')
            .then((res) => {
                setDivisions(res.data.content);
            })
            .catch((err) => {
                setShowLoader(false);
            });

        API.get('tags/all')
            .then((res) => {
                const gotTags = res.data.content.map((t) => {
                    return {
                        id: t.id.toString(),
                        name: t.name,
                    }
                })
                setTagSuggestions(gotTags);
                setShowLoader(false);

            })
            .catch((err) => {
                setShowLoader(false);
            });
    }


    const addNewRow = () => {
        const uid = uuid();
        let divIcon = null;
        let divIconName = null;
        let divIconColor = '0,0,0,0,0,0';

        if (authUser.division.name.includes('Mr Price')) {
            divIcon = getIconByName('redcap');
            divIconName = 'redcap';
            divIconColor = divIcon.color;
        }
        else if (authUser.division.name.toLowerCase() === 'sheet street') {
            divIcon = getIconByName('ssHeart');
            divIconName = 'ssHeart';
            divIconColor = divIcon.color;
        }
        else if (authUser.division.name.toLowerCase() === 'miladys') {
            divIcon = getIconByName('miladysM');
            divIconName = 'miladysM';
            divIconColor = divIcon.color;
        }

        setRows([
            ...rows,
            {
                id: uid,
                division: authUser.division.id.toString() || null,
                destination: '',
                title: '',
                privacy: 'none',
                tags: [],
                utm_campaign: '',
                errors: {},
                icon: divIcon.icon,
                icon_name: divIconName,
                eye_color: divIconColor
            }
        ])
    }

    useEffect(() => {
        setShowLoader(true);
        setPageInfo({ title: "Create QR Codes", path: loc.pathname, hasCreate: false });
        getDivisionsAndTags();

        if (rows.length < 1) {
            addNewRow();
        }
    }, [])

    const updateRow = (index, field, value) => {
        let rowsCopy = [...rows];
        rowsCopy[index][field] = value;
        setRows(rowsCopy);
    }

    const hasErrors = () => {
        let res = rows.filter((r) => {
            return !r.title.length || !r.destination.length || !r.division.length || !r.privacy.length;
        });
        return res.length > 0;
    }

    const doubleCheck = () => {

        let newRows = rows.map((r) => {
            if (!r.destination || !r.destination.length) {
                r.errors.destination = "Please enter a destination URL. eg: https://mrpmoney.com or https://www.mrphome.com";
            }

            if (!r.title || !r.title.length) {
                r.errors.title = "Please enter a title.";
            }

            if (!r.division) {
                r.errors.division = "Please enter a division.";
            }

            if (!r.privacy || !r.privacy.length) {
                r.errors.privacy = "Please specify a privacy setting.";
            }

            return r;
        })

        setRows(newRows);

        if (hasErrors()) {

            Swal.fire({
                title: 'Errors',
                text: `There are one or more errors that need fixing before you can create ${newRows.length > 1 ? "these QR codes." : "this QR code."}.`,
                icon: 'error',
                position: 'center',
                showConfirmButton: true,
            })
        }
        else {
            if (rows.length > 1) {
                Swal.fire({
                    title: 'Confirmation',
                    text: "You are about to bulk create these links. Are you sure you want to do this?",
                    icon: 'warning',
                    position: 'center',
                    showConfirmButton: true,
                    showDenyButton: true,
                })
                    .then((res) => {
                        if (res.isConfirmed) {
                            submitLinks();
                        }
                    })
            }
            else {
                submitLinks();
            }
        }

    }

    const formattedRows = () => {

        let newRows = [...rows];

        newRows = newRows.map((r) => {

            let newRow = { ...r };

            let tagNames = newRow.tags.map((t) => {
                return t.name;
            })

            newRow.tags = tagNames.join(',');
            return newRow;

        });

        return newRows;
    }

    const submitLinks = () => {

        const fRows = formattedRows();

        setShowLoader(true);

        API.post('qr/create', { links: fRows })
            .then((res) => {
                setShowLoader(false);

                if (res.response && res.response.data && res.response.data.errors) {

                    const ers = res.response.data.errors;
                    let fieldNames = Object.keys(ers);
                    let fields = fieldNames.map(field => {
                        let f = field.split('.');
                        f.shift();
                        return {
                            index: f[0],
                            name: f[1],
                            message: ers[`links.${[f[0]]}.${[f[1]]}`][0],
                        }
                    });

                    fields.forEach((f) => {
                        updateRow(Number(f.index), 'errors', { ...rows[f.index].errors, [f.name]: f.message });
                    });
                }

                else if (res.data && res.data.content) {
                    const newLinks = res.data.content;

                    nav('/qr-codes/create/success', {
                        state: {
                            links: newLinks,
                        }
                    })
                }

            })
            .catch((err) => {
                console.log(err);
            });

    }

    return (
        <Container fluid>
            {
                rows.length &&
                <>
                    {
                        rows.map((row, rowPosition) => {
                            return <CreateQRCodeRow key={row.id} rowID={row.id} rowPosition={rowPosition} rows={rows} setRows={setRows} divisions={divisions} tagSuggestions={tagSuggestions} setTagSuggestions={setTagSuggestions} rowLinkProps={row} addRow={addNewRow} updateRow={updateRow} isFetchingRowData={isFetchingRowData} setIsFetchingRowData={setIsFetchingRowData} QRIcons={QRIcons} getIconByName={getIconByName} showLoader={showLoader} setShowLoader={setShowLoader} />
                        })
                    }

                    <div className="row mb-5">
                        <div className="col-12 text-end">
                            <Button variant="success" onClick={doubleCheck} disabled={showLoader || isFetchingRowData}>
                                {rows.length > 1 ? "Bulk Create" : "Create"}
                            </Button>
                        </div>
                    </div>
                </>
            }

        </Container>
    );
}

export default CreateQRCodes;
