import React from 'react';
import { WithContext as ReactTags } from "react-tag-input";

import './TagSelector.css';

function TagSelector({options, tags, suggestions}) {

    const KeyCodes = {
        comma: 188,
        enter: 13,
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    return (
        <ReactTags
            id={options.id || null}
            labelField={options.labelField || 'name'}
            handleDelete={options.handleDelete || null}
            handleAddition={options.handleAddition || null}
            handleDrag={options.handleDrag || null}
            delimiters={options.delimiters || delimiters}
            handleTagClick={options.handleTagClick}
            onClearAll={options.onClearAll}
            onTagUpdate={options.onTagUpdate}
            suggestions={suggestions}
            placeholder={options.placeholder || "Search Tags"}
            minQueryLength={options.minQueryLength || 2}
            maxLength={options.maxLength || 50}
            autofocus={options.autofocus || false}
            allowDeleteFromEmptyInput={options.allowDeleteFromEmptyInput || false}
            autocomplete={options.autocomplete || false}
            readOnly={options.readOnly || false}
            allowUnique={options.allowUnique || false}
            allowDragDrop={options.allowDragDrop || false}
            inline={options.inline || false}
            allowAdditionFromPaste={options.allowAdditionFromPaste || false}
            editable={options.editable || false}
            clearAll={options.clearAll || false}
            tags={tags}
            // [{"id":"Thailand","text":"Thailand"},{"id":"India","text":"India"},{"id":"Vietnam","text":"Vietnam"},{"id":"Turkey","text":"Turkey"}]
            classNames={{
                // tags: 'tags-wrapper',    
                tagInput: `tag-input input-group ${options.classNames.tagInput}`,
                tagInputField: `form-control ${options.classNames.tagInputField}`,
                // selected: 'selectedClass',
                tag: 'badge rounded-pill bg-primary',
                // remove: 'btn',
                suggestions: 'tag-suggestions',
                // activeSuggestion: 'activeSuggestionClass',
                // editTagInput: 'editTagInputClass',
                editTagInputField: 'form-control form-control-sm mb-3',
                clearAll: 'btn input-group-btn',
              }}
    />
    )
}

export default TagSelector;
