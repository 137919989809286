import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API/API';
import { LoaderContext } from '../../context/loaderContext';

import { PageContext } from '../../context/pageContext';
import { currentUrlHasQueries, getQueryParams, makeSearchQuery } from '../../utils/urlUtils';
import { showConfirmation, showToast } from '../../utils/utils';
import CustomPagination from '../../components/Pagination/Pagination';

import './Users.css';
import UsersDesktop from './UsersDesktop';
import UsersMobile from './UsersMobile';

function Users() {

    const apiPath = "/users";
    const urlPath = "/users";

    const [users, setUsers] = useState([]);
    const [activeFilters, setActiveFilters] = useState({ division: '', search: '' })
    const [activeQueryString, setActiveQueryString] = useState('')
    const [divisions, setDivisions] = useState([]);
    const { showLoader, setShowLoader } = useContext(LoaderContext);
    const [paginationInfo, setPaginationInfo] = useState({ users: [] });
    const { setPageInfo } = useContext(PageContext);

    const navigate = useNavigate();
    const loc = useLocation();

    const getDivisions = () => {

        if (!showLoader) {
            setShowLoader(true);
        }
        API.get('divisions')
            .then((res) => {
                setDivisions(res.data.content);

                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    const getUsers = (url) => {

        if (!showLoader) setShowLoader(true);

        API.get(url)
            .then((res) => {

                const content = res.data.content;
                setUsers(content.data);
                setPaginationInfo({
                    links: content.links,
                    currentPage: content.current_page,
                    lastPage: content.last_page,
                    currentCount: content.length,
                    firstItemIndex: content.from,
                    lastItemIndex: content.to,
                    totalPages: content.total,

                })
                getDivisions();

            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    useEffect(() => {
        setPageInfo({ title: "Users", path: loc.pathname, hasCreate: true, createURL: 'users/create' });

        const queries = getQueryParams();
        const str = makeSearchQuery(queries);
        setActiveQueryString(str);

        getUsers(`${apiPath}${str}`);

        if (loc.state && loc.state.newUserCreated) {
            const newUser = loc.state.newUserCreated;
            showToast({ title: `User ${newUser.firstname} ${newUser.lastname} created successfully` });
        }

    }, []);

    const updateFilters = (name, value) => {

        const filts = { ...activeFilters, [name]: value };
        setActiveFilters(filts);
        const str = makeSearchQuery(filts);
        setActiveQueryString(str);

    }

    const hasActiveFilters = () => {
        return activeFilters.division || activeFilters.search || currentUrlHasQueries();
    }

    const deleteUser = (id) => {
        API.delete(`user/delete/${id}`)
            .then((res) => {

                if (res.status === 200) {

                    getUsers(`${apiPath}${activeQueryString}`);
                    showToast({ title: "User Deleted", text: `${res.data.content.firstname} ${res.data.content.lastname} deleted successfully`, });
                }

            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    const deleteUserConfirm = (user) => {

        const text = user.links_count > 0 ? <p>Are you sure you want to delete this user?<br />Any links they've created will be assigned to you.</p> : <p>Are you sure you want to delete this user?</p>

        const del = () => {
            deleteUser(user.id)
        }

        showConfirmation({ title: 'Confirm Deletion', html: text, showDenyButton: true, showConfirmButton: true }, del);

    }

    return (
        <Container fluid>
            {
                (!showLoader && hasActiveFilters()) || (!hasActiveFilters() && users.length) ?

                    <div className="card mb-5">
                        <div className="card-body">

                            <div className="d-xl-none">
                                <UsersMobile
                                    users={users}
                                    divisions={divisions}
                                    updateFilters={updateFilters}
                                    activeFilters={activeFilters}
                                    urlPath={urlPath}
                                    apiPath={apiPath}
                                    getUsers={getUsers}
                                    activeQueryString={activeQueryString}
                                    setActiveFilters={setActiveFilters}
                                    deleteUserConfirm={deleteUserConfirm}
                                    hasActiveFilters={hasActiveFilters}
                                    navigate={navigate}
                                />
                            </div>

                            <div className="d-none d-xl-block">
                                <UsersDesktop
                                    users={users}
                                    divisions={divisions}
                                    updateFilters={updateFilters}
                                    activeFilters={activeFilters}
                                    urlPath={urlPath}
                                    apiPath={apiPath}
                                    getUsers={getUsers}
                                    activeQueryString={activeQueryString}
                                    setActiveFilters={setActiveFilters}
                                    deleteUserConfirm={deleteUserConfirm}
                                    hasActiveFilters={hasActiveFilters}
                                    navigate={navigate}
                                />

                            </div>


                        </div>
                        <div className="card-footer">
                            <CustomPagination
                                data={users}
                                apiPath={apiPath}
                                pageInfo={paginationInfo}
                                getFunction={getUsers}
                            />
                        </div>
                    </div>

                    : ''

            }



        </Container>
    );
}

export default Users;
