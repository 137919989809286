import React, { useContext, useRef } from 'react'
import { Button, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap';
import { Copy, Edit, Eye, Info, List, Search, Trash, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { getQueryParams } from '../../utils/urlUtils';
import { applyFiltersAndNavigate, isDarkTheme, linkBlockLimit, shortLinkHost } from '../../utils/utils';

function LinksDesktop(
    {
        urlPath,
        apiPath,
        getLinks,
        hasActiveFilters,
        setActiveFilters,
        links,
        copyLink,
        deleteLinkConfirm,

        filters,
        divisions,
        users,
        updateFilters,
        activeFilters,
        activeQueryString,
        navigate


    }

) {

    const { showLoader } = useContext(LoaderContext);
    const { authUser } = useContext(AuthContext);

    const popoverParent = useRef(null);

    return (
        !showLoader || links.length ?
            links.length ?
                <>
                    <div className="row mb-4 align-items-center">
                        <div className="col-md-8">

                            <div className="input-group">
                                <span className={`input-group-text`} >Search</span>
                                {
                                    filters.includes('divisions') && divisions.length ?

                                        <select
                                            className="form-select"
                                            name="division"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                updateFilters('division', e.target.value)
                                            }}
                                            value={activeFilters.division || ''}
                                        >
                                            <option value="">All Divisions</option>
                                            {
                                                divisions.map((d, pos) => (
                                                    <option key={`d-${pos}`} value={d.id} >{d.name}</option>
                                                ))
                                            }

                                        </select>
                                        : ''
                                }

                                {
                                    filters.includes('user') && users.length ?

                                        <select
                                            className="form-select"
                                            name="user"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                updateFilters('user', e.target.value)
                                            }}
                                            value={activeFilters.user || ''}
                                        >
                                            <option value="">All Users</option>
                                            {
                                                users.map((d, pos) => (
                                                    <option key={`d-${pos}`} value={d.id} >{d.name}</option>
                                                ))
                                            }

                                        </select>
                                        : ''
                                }

                                {
                                    filters.includes('search') &&
                                    <input
                                        value={activeFilters.search}
                                        type="text"
                                        className="form-control"
                                        placeholder="Tag, keyword etc."
                                        name="search"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            updateFilters('search', e.target.value)
                                        }}

                                        onKeyUp={(e) => {

                                            if (e.key === "Enter") {
                                                applyFiltersAndNavigate(e, urlPath, apiPath, activeQueryString, getLinks, navigate)
                                            }
                                        }}
                                    />
                                }

                                {
                                    hasActiveFilters() ?
                                        <span className={`input-group-text cursor-pointer ${hasActiveFilters() ? 'bg-success' : ''}`} onClick={(e) => {
                                            applyFiltersAndNavigate(e, urlPath, apiPath, activeQueryString, getLinks, navigate)
                                        }} ><Search color={`${hasActiveFilters() ? '#fff' : '#353C43'}`} /></span> :
                                        <span className={`input-group-text`} ><Search /></span>
                                }
                            </div>

                        </div>
                        {
                            getQueryParams() ?
                                <div className="col-md-4 text-end">
                                    <Button as={Link} variant={`${isDarkTheme(authUser.theme) ? 'dark' : 'light'}`} size="sm" to={urlPath} onClick={() => {
                                        setActiveFilters({ division: '', search: '', user: '' });
                                        getLinks(apiPath);
                                    }}>Clear Filters</Button>
                                </div>
                                : ''
                        }

                    </div>

                    <Table striped bordered hover className="links-table">
                        <thead>
                            <tr>
                                <th className="link-title">Title</th>
                                <th className="division-logo">Division</th>
                                <th className="long-url">Long URL</th>
                                <th className="short-url">Short URL</th>
                                <th className="clicks">Clicks</th>
                                <th className="actions">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                links.map((link) => (

                                    <tr key={link.id}>
                                        <td className="link-title">
                                            {link.title}
                                        </td>
                                        <td className="division-logo">
                                            {
                                                link.division ?
                                                    link.division.logo ?
                                                        <img src={isDarkTheme(authUser.theme) ? link.division.logo_alt : link.division.logo} alt={link.division.name} />
                                                        : link.division.name
                                                    : "Unknown"
                                            }
                                        </td>

                                        <td className="long-url" ref={popoverParent}>
                                            <div className="row">
                                                <div className={`${link.utm_campaign || link.utm_source || link.utm_medium ? 'col-10' : 'col-12'}`}>
                                                    <OverlayTrigger

                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`longUrlTooltip-${link.id}`}>
                                                                {link.long_url}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <a href={link.long_url} target="_blank">{link.long_url.replace('https://www.', '').replace('http://www.', '').replace('https://', '').replace('http://', '')}</a>

                                                    </OverlayTrigger>
                                                </div>

                                                {
                                                    link.utm_campaign || link.utm_source || link.utm_medium ?

                                                        <OverlayTrigger
                                                            trigger="click"
                                                            placement="auto"
                                                            container={popoverParent}
                                                            rootClose={true}
                                                            overlay={
                                                                <Popover id={`popover-link-${link.id}`}>

                                                                    <Popover.Body>
                                                                        <div>
                                                                            <div>
                                                                                This link has UTM parameters.
                                                                            </div>
                                                                            <hr />

                                                                            <Table className="link-info-table">
                                                                                <tbody>
                                                                                    {
                                                                                        link.utm_campaign ?
                                                                                            <tr>
                                                                                                <td>Campaign:</td>
                                                                                                <td>{link.utm_campaign}</td>
                                                                                            </tr>
                                                                                            : ''
                                                                                    }

                                                                                    {
                                                                                        link.utm_source ?
                                                                                            <tr>
                                                                                                <td>Source:</td>
                                                                                                <td>{link.utm_source}</td>
                                                                                            </tr>
                                                                                            : ''
                                                                                    }

                                                                                    {
                                                                                        link.utm_medium ?
                                                                                            <tr>
                                                                                                <td>Medium:</td>
                                                                                                <td>{link.utm_medium}</td>
                                                                                            </tr>
                                                                                            : ''
                                                                                    }

                                                                                </tbody>
                                                                            </Table>


                                                                        </div>
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                                        >

                                                            <div className="col-2 text-center" style={{ flexShrink: 1 }}>
                                                                <Info size="1em" className="cursor-pointer" />
                                                            </div>
                                                        </OverlayTrigger>

                                                        : ''
                                                }

                                            </div>
                                        </td>

                                        <td className="short-url">

                                            <div className="row">
                                                <a href={`${shortLinkHost}/${link.short_url}`} target="_blank">{link.short_url}</a>
                                            </div>

                                        </td>

                                        <td className="clicks">
                                            {link.hits.total}
                                        </td>

                                        <td className="actions">
                                            <div className="container">
                                                <div className="row">

                                                    <div className="col-auto">
                                                        <OverlayTrigger

                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`copyLink-${link.id}`}>
                                                                    Copy Short Link
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Copy size={16} className="cursor-pointer text-success"

                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    copyLink(link.short_url)
                                                                }}
                                                            />

                                                        </OverlayTrigger>

                                                    </div>

                                                    <div className="col-auto">
                                                        <Link to={`/link/${link.id}`}>
                                                            <Eye size={16} className="cursor-pointer text-info" />
                                                        </Link>
                                                    </div>
                                                    <div className="col-auto">

                                                        {
                                                            link.user.id == authUser.id || authUser.isAdmin ?
                                                                <Link to={{
                                                                    pathname: `/link/edit/${link.id}`,
                                                                    x: {
                                                                        returnUrl: urlPath
                                                                    }
                                                                }}>
                                                                    <Edit size={16} className="cursor-pointer text-primary" />
                                                                </Link>
                                                                : ''

                                                        }

                                                    </div>

                                                    <div className="col-auto">

                                                        {
                                                            link.user.id == authUser.id || authUser.isAdmin ?

                                                                link.hits.total >= linkBlockLimit ?
                                                                    <OverlayTrigger

                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id={`deleteTooltip-${link.id}`}>
                                                                                This link has been visited too many times to delete it now.
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Trash size={16} className="text-muted" />

                                                                    </OverlayTrigger>

                                                                    :
                                                                    <Trash2 size={16} className="cursor-pointer text-danger" onClick={() => {
                                                                        deleteLinkConfirm(link)
                                                                    }} />

                                                                : ''
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </Table>
                </>
                :
                hasActiveFilters() ? <div className="alert alert-warning">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">There are no links matching your search.</div>
                        <div className="col-auto">
                            <Button as={Link} variant="light" size="sm" to={urlPath} onClick={() => {
                                setActiveFilters({ division: '', search: '', user: '' });
                                getLinks(apiPath);
                            }}>Clear Filters</Button>
                        </div>
                    </div>

                </div>
                    : <div className="alert alert-warning">You have no links</div>
            : ''

    )
}

export default LinksDesktop