import React, { useContext, useEffect, useState } from 'react'
import API from '../../API/API';

import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';

import './Login.css'
import MRPGLogo from './mrpg.svg';

function Login() {

    const [info, setInfo] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});
    const { authDispatch } = useContext(AuthContext)
    const { setShowLoader } = useContext(LoaderContext);

    useEffect(() => {
        document.title = `Login | MRP Short Link Creator`;
    }, [])

    const userLogin = (e) => {
        e.preventDefault();
        setErrors({});

        setShowLoader(true);

        const handleError = (res) => {
            if (res.response && res.response.data && res.response.data.errors) {
                setErrors(res.response.data.errors);
                authDispatch({ type: "LOGIN_FAILED", payload: res.response.data.message })
            }
            else if (res.response && res.response.data && res.response.data.message) {
                authDispatch({ type: "LOGIN_FAILED", payload: res.response.data.message })
            }
            else {
                authDispatch({ type: "LOGIN_FAILED", payload: "Something went wrong while trying to log you in." })
            }
            setShowLoader(false)
        }

        API.post('login', { email: info.email, password: info.password }, false)
            .then(res => {
                setShowLoader(false);
                if (res.response && res.response.data && res.response.data.errors) {
                    handleError(res);
                }
                else if (res.status >= 200 && res.status < 300) {
                    if (res.data && res.data.content) {
                        let content = res.data.content;

                        let userInfo = {
                            token: content.api_token,
                            avatar: content.avatar,
                            avatar2: `https://ui-avatars.com/api/?color=FFFFFF&background=E02124&size=200&length=1&name=${content.firstname}`,
                            division: content.division,
                            email: content.email,
                            firstname: content.firstname,
                            id: content.id,
                            isAdmin: content.is_admin,
                            lastname: content.lastname,
                            theme: content.theme
                        }

                        authDispatch({ type: "LOGIN_SUCCESS", payload: userInfo })
                    }
                    else {
                        setShowLoader(false);
                        handleError(res);
                    }
                }
            })
            .catch((res) => {
                setShowLoader(false);
                handleError(res);
            });
    }

    return (
        <div className="auth-container flex-column">

            <div className="card col-md-8 col-lg-4">
                <div className="card-body p-5">

                    <div className="login-logo mb-5 text-center">
                        <img src={MRPGLogo} alt="Mr Price Group Limited" />
                    </div>

                    <p>Please login to access Mr Price Short Links.</p>
                    <form method="POST">
                        <div className="form-group mb-3">
                            <input
                                type="text"
                                name="email"
                                className="form-control mb-1"
                                placeholder="Email Address"
                                onChange={(e) => {
                                    setInfo({ ...info, email: e.target.value })
                                }}
                            />

                            {
                                errors.email && errors.email[0] ?
                                    <div className="invalid-feedback d-block">{errors.email}</div>
                                    : ''
                            }

                        </div>

                        <div className="form-group mb-3">
                            <input
                                type="password"
                                name="password"
                                className="form-control mb-1"
                                placeholder="Password"
                                onChange={(e) => {
                                    setInfo({ ...info, password: e.target.value })
                                }}
                            />

                            {
                                errors.password && errors.password[0] ?
                                    <div className="invalid-feedback d-block">{errors.password}</div>
                                    : ''
                            }


                        </div>

                        <div className="d-grid">
                            <button className="btn btn-block btn-primary" onClick={userLogin}>Login</button>
                        </div>
                    </form>

                    
                </div>
                <div className='card-footer'>
                <div className="text-end">
                        <small>v2.0b</small>
                    </div>
                </div>
            </div>
            


        </div>
    )
}

export default Login
