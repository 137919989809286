import React, { useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { PageContext } from '../../context/pageContext';

import Links from './Links';

function MyLinks() {

    const {setPageInfo} = useContext(PageContext);
    
    const loc = useLocation();

    useEffect( () => {
        setPageInfo({title: "My Links", path: loc.pathname, hasCreate: true, createURL : 'links/create'});
    }, [])


    return (
            <Links apiPath="/my-links" urlPath="/links/mine" linkFilters={['divisions', 'search']} />
        );
}

export default MyLinks;
