import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HelpCircle } from 'react-feather';

/**
 * Creates an html label element
 * @param {string} label The label title
 * @param {string} prop The name of the object property in the state
 * @param {boolean} required Whether or not the input associated with this label is required.
 * @param {string} id the id of the input element. Used for the "for" attribute.
 * @param {string} classNames Any additional classes the label should have
 * @param {object} errorsObj The state object that contains the errors
 * @returns {object} JSX div and label component
 */
export const labelHTML = (label, prop, required = false, id = "1", classNames = "", errorsObj = {}) => {
    return (
        <div className="d-flex justify-content-between">
            <label htmlFor={`${prop}-${id}`} className={`${required ? 'required' : ''} ${classNames}`} >{label}</label>
            {
                errorsObj[prop] && errorsObj[prop].length &&
                errorIndicator(errorsObj[prop])
            }
        </div>
    )
}

/**
 * Creates a tooltip to display input errors
 * @param {string} text The text to show in the error indicator tooltip
 * @returns {object} Bootstrap Overlay Trigger & Tooltip components
 */
const errorIndicator = (text = "Invalid input data.") => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>{text}</Tooltip>
            }
        >
            <HelpCircle size="1em" color="red" />
        </OverlayTrigger>
    )
}

/**
 * Removes an error from an errors state object
 * @param {string} prop The object property (error name)
 * @param {object} errorsObject The state object containing the errors
 * @param {function} setFunction The set state function that updates the errors object
 */
export const removeError = (prop, errorsObject, setFunction) => {
    let errorsCopy = { ...errorsObject };
    delete errorsCopy[prop];
    if (typeof setFunction === 'function') setFunction(errorsCopy);
}