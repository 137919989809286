import { createContext, useState } from "react"
  
export const PageContext = createContext();

export const PageContextProvider = ({ children }) => {
  const [pageInfo, setPageInfo] = useState({title: <>&nbsp;</>, path: '', hasCreate: false});
  
  return (
    <PageContext.Provider value={{ pageInfo, setPageInfo }} >
      {children}
    </PageContext.Provider>
  );
};
