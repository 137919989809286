import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { CheckCircle } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API/API';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { PageContext } from '../../context/pageContext';
import { downloadFile, generateCSV, isDarkTheme } from '../../utils/utils';
import JSZip from 'jszip';

import './QRCodes.css';
import { getIconByName } from './QRIcons/QRIcons';

function CreateQRCodesSuccess() {

    const { setShowLoader } = useContext(LoaderContext);
    const { setPageInfo } = useContext(PageContext);
    const [divisions, setDivisions] = useState([]);
    const [links, setLinks] = useState([]);
    const { authUser } = useContext(AuthContext);

    const navigate = useNavigate();
    const loc = useLocation();

    useEffect(() => {

        if (!loc.state.links || !loc.state.links.length) navigate('/dashboard');

        setPageInfo({ title: "QR Codes Created", path: loc.pathname, hasCreate: false });
        setLinks(loc.state.links);

        setShowLoader(true);

        API.get('divisions')
            .then(res => {
                setDivisions(res.data.content);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err.response)
                setShowLoader(false)
            })
    }, [])

    const generatePngAndSvg = (id, bg, fg) => {

        return new Promise((resolve) => {


            let phantom = document.createElement('canvas');
            phantom.setAttribute("id", `qr-canvas-${id}`);
            phantom.setAttribute("width", "512");
            phantom.setAttribute("height", "512");
            phantom.setAttribute("style", "position: absolute; left: -100000px; top: -1000000px;");
            document.body.append(phantom);

            let c = phantom;
            let ctx = c.getContext("2d");

            let imageObj1 = new Image();
            imageObj1.src = bg.toString();

            let url;
            imageObj1.onload = () => {
                ctx.drawImage(imageObj1, 0, 0);

                // if (fg && fg.length) {

                //     let imageObj2 = new Image();
                //     imageObj2.src = fg.toString();

                //     imageObj2.onload = () => {
                //         ctx.drawImage(imageObj2, 198, 198, 120, 120);
                //         url = c.toDataURL();

                //         c.remove();
                //         resolve({ id, url });
                //     }

                // }
                // else {
                    url = c.toDataURL();

                    c.remove();
                    resolve({ id, 
                        png: url,
                        svg: bg
                    });
                // }

            }
        })
    }

    const generatePNGs = () => {
        const promises = [];

        for (let i = 0; i < links.length; i++) {
            let ic = getIconByName(links[i].icon_name);
            promises.push(generatePngAndSvg(links[i].id, links[i].image, ic.icon));
        }

        return Promise.all(promises);

    }

    function b64toBlob(data) {

        var string = atob(data.split(',')[1]);
        var buffer = new ArrayBuffer(string.length);
        var ia = new Uint8Array(buffer);

        for (var i = 0; i < string.length; i++) {
            ia[i] = string.charCodeAt(i);
        }
        return new Blob([buffer], { type: 'image/png' });
    }

    const download = () => {

        generatePNGs()
            .then((pngs) => {

                const zip = new JSZip();

                let preppedLinks = [];

                for (let i = 0; i < links.length; i++) {
                    let qrFilename = `${links[i].title} ${links[i].utm_campaign}`;
                    qrFilename = `${qrFilename.substring(0, 200)}-${i}`;
                    qrFilename = qrFilename.trim().replace(/\s|_/g, '-');
                    qrFilename = qrFilename.trim().replace(/[/\\?%*:|"<>]/g, '-');
                    qrFilename = `${qrFilename.replace(/---/g, '-')}`;
                    
                    let pngImgIndex = pngs.findIndex((p) => {
                        return Number(p.id) === Number(links[i].id);
                    });

                    let pngImg = pngs[pngImgIndex];

                    zip.file(qrFilename + ".svg", b64toBlob(pngImg.svg));
                    zip.file(qrFilename + ".png", b64toBlob(pngImg.png));

                    let csvRow = {};

                    csvRow.id = links[i].id;
                    csvRow.title = links[i].title;
                    csvRow.destination = links[i].destination;
                    csvRow.division = links[i].division.name;
                    csvRow.tags = links[i].tags.join(' - ');
                    csvRow.utm_campaign = links[i].utm_campaign;
                    csvRow.qrcode_filename = qrFilename;

                    preppedLinks.push(csvRow);
                }


                const date = new Date();
                const y = date.getFullYear();
                const m = ("0" + (date.getMonth() + 1)).slice(-2);
                const d = ("0" + date.getDate()).slice(-2);
                const t = ("0" + date.getHours()).slice(-2) + ("0" + date.getMinutes()).slice(-2)

                const csvFilename = `qrcodes-${y}${m}${d}-${t}`;
                const csv = generateCSV(preppedLinks);

                zip.file(`${csvFilename}.csv`, csv);

                zip.generateAsync({
                    type: "blob"
                }).then(function (content) {
                    downloadFile(content, csvFilename)
                })
                    .catch(errr => {
                        console.log(errr)
                    });
            })

    }

    return (
        <Container fluid>

            <div className="row mb-3">
                <div className="col-12">
                    <div className="alert alert-success d-flex justify-content-between">
                        <span>
                            <CheckCircle /> Your links were created successfully.
                        </span>
                        <Button variant="info" size="sm" className="ms-auto" onClick={download}>Download All</Button>
                    </div>
                </div>
            </div>

            <Table striped bordered hover className="links-table">
                <thead>
                    <tr>
                        <th className="link-title">Title</th>
                        <th className="division-logo">Division</th>
                        <th className="long-url">Destination</th>
                        <th className="tags">Tags</th>
                        <th className="utm_campaign">UTM Campaign</th>
                        <th className="privacy">Privacy</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        links && links.length ?
                            links.map((l) => {
                                return (
                                    <tr key={l.id}>
                                        <td className="link-title">{l.title}</td>
                                        <td className="division-logo">
                                            {
                                                l.division.logo && <img src={isDarkTheme(authUser.theme) ? l.division.logo_alt : l.division.logo} alt={l.division.name} />
                                            }
                                        </td>

                                        <td className="long-url">

                                            <OverlayTrigger

                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`longUrlTooltip-${l.id}`}>
                                                        {l.destination}
                                                    </Tooltip>
                                                }
                                            >
                                                <a href={l.destination} target="_blank">{l.destination.replace('https://www.', '').replace('http://www.', '').replace('https://', '').replace('http://', '')}</a>

                                            </OverlayTrigger>

                                        </td>

                                        <td className="tags">
                                            {
                                                l.tags.length ?
                                                    l.tags.map((t, pos) => {
                                                        return (
                                                            <div key={`${t}-${pos}`} className="badge rounded-pill bg-primary me-2 mb-1">{t}</div>
                                                        )
                                                    }) : ''
                                            }
                                        </td>
                                        <td className="utm_campaign">{l.utm_campaign}</td>
                                        <td className="privacy">
                                            {
                                                l.privacy === 'user' ? 'Private' :
                                                    l.privacy === 'group' ? "Group"
                                                        : 'None'
                                            }
                                        </td>
                                    </tr>
                                )
                            }) : null
                    }
                </tbody>

            </Table>
        </Container>
    )
}

export default CreateQRCodesSuccess;
