import React, { useContext, useEffect, useState } from 'react';
import API from '../../API/API';
import { LoaderContext } from '../../context/loaderContext';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { copyToClipboard, linkBlockLimit, shortLinkHost, showConfirmation, showToast } from '../../utils/utils';

import './Links.css';
import { currentUrlHasQueries, getQueryParams, makeSearchQuery } from '../../utils/urlUtils';
import CustomPagination from '../../components/Pagination/Pagination';
import LinksDesktop from './LinksDesktop';
import LinksMobile from './LinksMobile';


function Links({ apiPath, urlPath, linkFilters }) {

    const [links, setLinks] = useState([]);
    const [filters, setFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState({ division: '', search: '', user: '' })
    const [activeQueryString, setActiveQueryString] = useState('')
    const [divisions, setDivisions] = useState([]);
    const [users, setUsers] = useState([]);

    const { showLoader, setShowLoader } = useContext(LoaderContext);
    const [paginationInfo, setPaginationInfo] = useState({ links: [] });

    const navigate = useNavigate();

    const getFilters = () => {

        API.get('/link-filters')
            .then((res) => {
                const filts = res.data.content;
                setDivisions(filts.divisions);
                setUsers(filts.users);
                setShowLoader(false);

            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    const getLinks = (url, blocker = true) => {

        if (!showLoader && blocker) setShowLoader(true);

        API.get(url)
            .then((res) => {
                const content = res.data.content;
                setLinks(content.data);
                setPaginationInfo({
                    links: content.links,
                    currentPage: content.current_page,
                    lastPage: content.last_page,
                    currentCount: content.length,
                    firstItemIndex: content.from,
                    lastItemIndex: content.to,
                    totalPages: content.total,

                })
                getFilters();

            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    useEffect(() => {

        const queries = getQueryParams();
        const str = makeSearchQuery(queries);
        setActiveQueryString(str);

        getLinks(`${apiPath}${str}`);

        if (queries) setActiveFilters(queries);
        else setActiveFilters({ division: '', search: '', user: '' })

        const filts = linkFilters != undefined ? linkFilters : [];
        setFilters(filts);

    }, [])

    const copyLink = (key) => {
        copyToClipboard(`${shortLinkHost}/${key}`, showToast({ title: 'Short Link Copied' }));
    }



    const updateFilters = (name, value) => {

        const filts = { ...activeFilters, [name]: value };
        setActiveFilters(filts);
        const str = makeSearchQuery(filts);
        setActiveQueryString(str);

    }

    const hasActiveFilters = () => {
        return activeFilters.division || activeFilters.search || activeFilters.user || currentUrlHasQueries();
    }

    const deleteLink = (id) => {
        API.delete(`link/delete/${id}`)
            .then((res) => {

                if (res.status === 200) {

                    const queries = getQueryParams();
                    const str = makeSearchQuery(queries);
                    setActiveQueryString(str);

                    getLinks(`${apiPath}${str}`);

                    showToast({ title: `${res.data.content.title} deleted successfully` });
                }

            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    const deleteLinkConfirm = (link) => {

        const del = () => deleteLink(link.id);

        if (link.hits.total >= linkBlockLimit)
            showConfirmation({ title: "Can't Delete Link", text: "This link has had too many clicks and can't be deleted.", icon: 'warning' });
        else
            showConfirmation({ title: "Confirm Deletion", html: <p>Are you sure you want to delete this link?<br /> <br />{link.title}<br />{link.url}</p>, icon: 'error', showDenyButton: true }, del);
    }

    return (
        <Container fluid>
            {
                !showLoader ?

                    (!showLoader && filters.length && hasActiveFilters()) || (filters.length && !hasActiveFilters() && links.length) ?

                        <div className="card mb-5">
                            <div className="card-body">

                                <div className="d-xl-none">
                                    <LinksMobile
                                        urlPath={urlPath}
                                        apiPath={apiPath}
                                        getLinks={getLinks}
                                        navigate={navigate}
                                        hasActiveFilters={hasActiveFilters}
                                        setActiveFilters={setActiveFilters}
                                        links={links}
                                        copyLink={copyLink}
                                        deleteLinkConfirm={deleteLinkConfirm}

                                        filters={filters}
                                        divisions={divisions}
                                        users={users}
                                        updateFilters={updateFilters}
                                        activeFilters={activeFilters}
                                        activeQueryString={activeQueryString}
                                    />
                                </div>
                                <div className="d-none d-xl-block">

                                    <LinksDesktop
                                        urlPath={urlPath}
                                        apiPath={apiPath}
                                        getLinks={getLinks}
                                        navigate={navigate}
                                        hasActiveFilters={hasActiveFilters}
                                        setActiveFilters={setActiveFilters}
                                        links={links}
                                        copyLink={copyLink}
                                        deleteLinkConfirm={deleteLinkConfirm}

                                        filters={filters}
                                        divisions={divisions}
                                        users={users}
                                        updateFilters={updateFilters}
                                        activeFilters={activeFilters}
                                        activeQueryString={activeQueryString}
                                    />

                                </div>

                            </div>


                            <div className="card-footer">
                                <CustomPagination
                                    data={links}
                                    apiPath={apiPath}
                                    pageInfo={paginationInfo}
                                    getFunction={getLinks}
                                />
                            </div>

                        </div>


                        : <div className="alert alert-warning">You have no links.</div>

                    : ''
            }



        </Container>
    );
}

export default Links;
