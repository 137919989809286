import React, { useContext } from 'react'
import {  Accordion, Nav } from 'react-bootstrap';
import { Circle, Columns, Grid, Link2, LogOut, Menu, User, Users, X } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API/API';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { PageContext } from '../../context/pageContext';
import { SidebarContext } from '../../context/sidebarContext';
import { isDarkTheme } from '../../utils/utils';


import './Sidebar.css';
import './Sidebar.Dark.css';

const MenuLinks = () => {

    const {pageInfo} = useContext(PageContext);
    const {authUser, authDispatch} = useContext(AuthContext);
    const {sidebarIsOpen, setSidebarIsOpen } = useContext(SidebarContext);
    const {setShowLoader} = useContext(LoaderContext);

    const nav = useNavigate();

    const isCurrentMenu = (menuItem) => {
        if( menuItem.path && pageInfo.path.startsWith('/' + menuItem.path)){
            return true;
        }
        return false;
    }

    const isCurrentSubmenu = (menuItem) => {
        if( menuItem.path && pageInfo.path.startsWith('/' + menuItem.path)){
            return true;
        }
        return false;
    }

    const menuItems = [
        {
            label: "Dashboard",
            path: "dashboard",
            isAdminOnly: false,
            icon: <Columns size="1em" />,
        },
        {
            label: "Short Links",
            isAdminOnly: false,
            icon: <Link2 size="1em"/>,
            path: 'links',
            submenus : [
                {
                    label: "My Links",
                    path: "links/mine",
                    isAdminOnly: false
                },
        
                {
                    label: "All Links",
                    path: "links/all",
                    isAdminOnly: false
                },
            ]   
        },

        {
            label: "QR Codes",
            isAdminOnly: false,
            icon: <Grid size="1em"/>,
            path: 'qr-codes',
            submenus : [
                {
                    label: "My QR Codes",
                    path: "qr-codes/mine",
                    isAdminOnly: false
                },
        
                {
                    label: "All QR Codes",
                    path: "qr-codes/all",
                    isAdminOnly: false
                },
            ]   
        },
        
        {
            label: "My Profile",
            path: "profile",
            isAdminOnly: false,
            icon: <User size="1em" />,
        },
        {
            label: "Manage Users",
            path: "users",
            isAdminOnly: true,
            icon: <Users size="1em" />,
        },

    ];

    const logout = (e) => {
        e.preventDefault();
        setShowLoader(true)
        setSidebarIsOpen(!sidebarIsOpen);
        API.post('logout')
        .then( (res) => {
            setShowLoader(false)
            authDispatch({type: "LOGOUT"})
            nav('/login');
        } )
        .catch ( (err) => {
            setShowLoader(false);
            console.log(err);
            }
        );
    }
    
    return (
            
            <Accordion className="menu-links flex-column" flush>
                {
                    menuItems.map( (m, pos) => {

                        if(!m.isAdminOnly || (m.isAdminOnly && authUser.isAdmin)) {
                            return (
                                !m.submenus || m.submenus.lengh < 1 ? 
                                <Accordion.Item 
                                    key={`menu-${pos}`}
                                    eventKey={`menu-${pos}`} 
                                    as={Link} 
                                    to={m.path} 
                                    className={`menu-item no-submenu`} 
                                    onClick={ () => setSidebarIsOpen(!sidebarIsOpen) }
                                >
                                    <Accordion.Header as="div" className={`menu-label-wrapper ${isCurrentMenu(m) ? "current-menu-item" : ''}`}><span className="menu-icon">{m.icon}</span><span className="menu-label">{m.label}</span></Accordion.Header>
                                   
                                </Accordion.Item>

                                :
                                <Accordion.Item eventKey={`menu-${pos}`} key={`menu-${pos}`} className="menu-item">
                                    <Accordion.Header as="div" className={`menu-label-wrapper ${isCurrentMenu(m) ? "current-menu-item" : ''}`}><span className="menu-icon">{m.icon}</span><span className="menu-label">{m.label}</span></Accordion.Header>
                                    <Accordion.Body>
                                        <Nav className="flex-column">
                                            {
                                                m.submenus.map( (s, spos) => {
                                                    return <Nav.Link
                                                            as={Link} 
                                                            to={s.path} 
                                                            key={spos} 
                                                            onClick={ () =>{
                                                                    setSidebarIsOpen(!sidebarIsOpen);
                                                                }
                                                            }
                                                            className={`submenu-item menu-label-wrapper ${isCurrentSubmenu(s) ? "current-submenu-item" : ''}`}>
                                                                <span className="menu-icon" ><Circle size="1em" /></span><span className="menu-label">{s.label}</span>
                                                        </Nav.Link>
                                                    
                                                        
                                                })
                                            }
                                            
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion.Item>
                                
                            )
                        }
                               
                    })

                }

                <Accordion.Item 
                    as={Link} 
                    to="/logout"
                    className={`menu-item no-submenu`} 
                    onClick={ logout}
                >
                    <Accordion.Header as="div" className={`menu-label-wrapper`}><span className="menu-icon"><LogOut size="1em" /></span><span className="menu-label">Logout</span></Accordion.Header>
                    
                </Accordion.Item>

            <div className='text-start menu-item no-submenu mt-3'>
                <Nav.Link className="menu-item d-xl-none menu-label accordion-button" onClick={ () => setSidebarIsOpen(!sidebarIsOpen)}><span className="menu-icon"><X size="1em" /></span><span>Close Menu</span></Nav.Link>
            </div>
            

            </Accordion>

            
    )
}

function Sidebar() {

    const {authUser} = useContext(AuthContext);

    return (
        <>  
            {
                authUser.firstname ?
                <>
                    <div className="sidebar-profile py-3 text-center">
                        <div className="avatar">
                            <Link to="profile">
                                <img src={authUser.avatar ? authUser.avatar : authUser.avatar2 } alt={authUser.firstname} />
                            </Link>
                        </div>

                        {
                            authUser.firstname ?
                            <div className="username">{ `${authUser.firstname} ${authUser.lastname}` }</div>
                            : ''
                        }

                        {
                            authUser.division && (authUser.division.logo || authUser.division.logo_alt) ?
                            <div className="division-logo">
                                <img 
                                src={ 
                                    isDarkTheme(authUser.theme) && authUser.division.logo_alt ? authUser.division.logo_alt :
                                    authUser.division.logo ? authUser.division.logo :
                                    authUser.division.logo_alt
                                }
                                alt={authUser.division.name} />
                            </div>
                            : ''
                        }
                    </div>

                    <hr />
                </>
                : ''
            }
            
            
            <div className="menu-large">
                <MenuLinks />
            </div>
        </>
        
    )
}

export default Sidebar
