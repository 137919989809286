import React, { useContext } from 'react'
import { Accordion, Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { getQueryParams } from '../../utils/urlUtils';
import { applyFiltersAndNavigate, isDarkTheme } from '../../utils/utils';

function UsersMobile(
    {
        users,
        divisions,
        updateFilters,
        activeFilters,
        urlPath,
        apiPath,
        getUsers,
        activeQueryString,
        setActiveFilters,
        deleteUserConfirm,
        hasActiveFilters,
        navigate
    }
) {

    const { showLoader } = useContext(LoaderContext);
    const { authUser } = useContext(AuthContext);
    return (
        !showLoader || users.length ?
            users.length ?

                <>
                    <div className="row mb-4 align-items-center">
                        <div className="col-12 mb-3">
                            <h5 className="card-heading text-center">Search / Filter</h5>
                        </div>

                        <div className="col-12 mb-2">
                            {
                                <input
                                    value={activeFilters.search}
                                    type="text"
                                    className="form-control"
                                    placeholder="Name or email"
                                    name="search"
                                    onChange={(e) => {
                                        e.preventDefault();
                                        updateFilters('search', e.target.value)
                                    }}

                                    onKeyUp={(e) => {

                                        if (e.key === "Enter") {
                                            applyFiltersAndNavigate(e, urlPath, apiPath, activeQueryString, getUsers, navigate);
                                        }
                                    }}
                                />
                            }
                        </div>

                        <div className="col-12 mb-2">

                            <div className="input-group">
                                {
                                    divisions.length ?

                                        <select
                                            className="form-select"
                                            name="division"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                updateFilters('division', e.target.value)
                                            }}
                                            value={activeFilters.division || ''}
                                        >
                                            <option value="">All Divisions</option>
                                            {
                                                divisions.map((d, pos) => (
                                                    <option key={`d-${pos}`} value={d.id} >{d.name}</option>
                                                ))
                                            }

                                        </select>
                                        : ''
                                }

                            </div>

                        </div>

                        <div className="col-6 d-grid">
                            {
                                hasActiveFilters() ?
                                    <Button variant={`${hasActiveFilters() ? 'success' : 'disabled'}`} onClick={(e) => {
                                        applyFiltersAndNavigate(e, urlPath, apiPath, activeQueryString, getUsers, navigate)
                                    }} >Search</Button> :
                                    <Button variant="dark" >Search</Button>
                            }
                        </div>

                        {
                            hasActiveFilters() ?
                                <div className="col-6 d-grid">
                                    <Button as={Link} variant="light" size="sm" to={urlPath} onClick={() => {
                                        setActiveFilters({ division: '', search: '' });
                                        getUsers(apiPath);
                                    }}>Clear Filters</Button>
                                </div>
                                : ''
                        }

                    </div>

                    <Accordion>
                        {
                            users.map((user) => (
                                <Accordion.Item eventKey={user.id} key={`user-mobile-${user.id}`}>
                                    <Accordion.Header>
                                        <div className="row w-100" style={{ justifyContent: "space-between", flexWrap: "nowrap" }}>
                                            <div className="col-auto" style={{ flexGrow: 1, flexShrink: 1 }}>
                                                {user.firstname} {user.lastname}
                                            </div>
                                            {
                                                Number(user.id) === Number(authUser.id) ?
                                                    <div className="col-auto ps-3 pe-4" style={{ flexShrink: 1 }}>
                                                        <Badge bg="success" >YOU</Badge>
                                                    </div> :
                                                    user.is_admin ?
                                                        <div className="col-auto ps-3 pe-4" style={{ flexShrink: 1 }}>
                                                            <Badge bg="info" >ADMIN</Badge>
                                                        </div>
                                                        : ''
                                            }

                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>

                                        <div className="row">
                                            <div className="col-auto">
                                                Email Address:
                                            </div>
                                            <div className="col-auto">
                                                <a href={`mailto:${user.email}`} target="_blank">{user.email}</a>
                                            </div>

                                        </div>

                                        <hr />

                                        <div className="row">
                                            <div className="col-auto">
                                                Division:
                                            </div>
                                            <div className="col-auto">
                                                <img style={{ height: "1rem", objectFit: "contain" }} src={isDarkTheme(authUser.theme) ? user.division.logo_alt : user.division.logo} alt={user.division.name} />
                                            </div>

                                        </div>

                                        <hr />

                                        <div className="row">
                                            <div className="col-auto">
                                                Links Shortened:
                                            </div>
                                            <div className="col-auto">
                                                {user.links_count}
                                            </div>

                                        </div>

                                        <hr />

                                        <div className="row justify-content-center">

                                            {
                                                authUser.isAdmin ?
                                                    <div className="col-auto">

                                                        <Button
                                                            variant="primary"
                                                            size="sm"
                                                            as={Link}
                                                            to={{
                                                                pathname: authUser.id == user.id ? '/profile' : `/user/edit/${user.id}`,
                                                                x: {
                                                                    returnUrl: urlPath
                                                                }
                                                            }}>EDIT</Button>
                                                    </div>
                                                    : ''
                                            }

                                            {
                                                authUser.isAdmin && Number(user.id) !== Number(authUser.id) ?
                                                    <div className="col-auto">

                                                        <Button
                                                            size="sm"
                                                            variant="danger"
                                                            onClick={() => {
                                                                deleteUserConfirm(user)
                                                            }}
                                                        >
                                                            DELETE
                                                        </Button>
                                                    </div>
                                                    : ''
                                            }

                                        </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        }
                    </Accordion>


                </>
                :
                hasActiveFilters()
                    ? <div className="alert alert-warning">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-auto">There are no users matching your search.</div>
                            <div className="col-auto">
                                <Button as={Link} variant="light" size="sm" to={urlPath} onClick={() => {
                                    setActiveFilters({ division: '', search: '' });
                                    getUsers(apiPath);
                                }}>Clear Filters</Button>
                            </div>
                        </div>

                    </div>
                    : <div className="alert alert-warning">There are no users.</div>
            : ''
    )
}

export default UsersMobile