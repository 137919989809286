export const isValidPassword = (pwd) => {
    if (!pwd.length) return false;
    let pat = new RegExp('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\\d\\x])(?=.*[@!$#%-_]).*$', 'i');
    return !!pat.test(pwd);
}

export const isValidURL = (url) => {
    if (!url) return false;
    let httpPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-\'a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!httpPattern.test(url);
}

export const isValidImageURL = (url) => {
    if (!url) return false;
    let pat = new RegExp('(?:([^:/?#]+):)?(?://([^/?#]*))?([^?#]*.(?:jpe?g|gif|png|webp))(?:\\\?([^#]*))?(?:#(.*))?', 'i');
    return !!pat.test(url);
}

export const isValidContactLink = (url) => {
    if (!url.length) return true;
    let telPattern = new RegExp('^(mailto:[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}|tel:\\d{10}|sms:\\d{10}|callto:\\d{10}|mms:|skype:)$', 'i');
    return !!telPattern.test(url);
}

export const isValidShortUrl = (key) => {
    let pat = new RegExp('[^A-Za-z0-9]', 'gi');
    return !pat.test(key);
}