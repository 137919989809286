import React, { useContext } from 'react'
import { Accordion, Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { getQueryParams } from '../../utils/urlUtils';
import { applyFiltersAndNavigate, isDarkTheme, linkBlockLimit } from '../../utils/utils';

function QRCodesMobile(
    {
        urlPath,
        apiPath,
        getLinks,
        hasActiveFilters,
        setActiveFilters,
        links,
        downloadQR,
        deleteLinkConfirm,

        filters,
        divisions,
        users,
        updateFilters,
        activeFilters,
        activeQueryString,
        navigate
    }
) {

    const { authUser } = useContext(AuthContext);

    return (
            links.length ?
                <>
                    <div className="row mb-4 align-items-center">
                        <div className="col-12 mb-3">
                            <h5 className="card-heading text-center">Search</h5>
                        </div>
                        <div className="col-12 mb-2">
                            {
                                filters.includes('search') &&
                                <input
                                    value={activeFilters.search}
                                    type="text"
                                    className="form-control"
                                    placeholder="Tag, keyword etc."
                                    name="search"
                                    onChange={(e) => {
                                        e.preventDefault();
                                        updateFilters('search', e.target.value)
                                    }}

                                    onKeyUp={(e) => {

                                        if (e.key === "Enter") {
                                            applyFiltersAndNavigate(e, urlPath, apiPath, activeQueryString, getLinks, navigate)
                                        }
                                    }}
                                />
                            }
                        </div>

                        <div className="col-12 mb-2">

                            <div className="input-group">
                                {
                                    filters.includes('divisions') && divisions.length ?

                                        <select
                                            className="form-select"
                                            name="division"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                updateFilters('division', e.target.value)
                                            }}
                                            value={activeFilters.division || ''}
                                        >
                                            <option value="">All Divisions</option>
                                            {
                                                divisions.map((d, pos) => (
                                                    <option key={`d-${pos}`} value={d.id} >{d.name}</option>
                                                ))
                                            }

                                        </select>
                                        : ''
                                }

                                {
                                    filters.includes('user') && users.length ?

                                        <select
                                            className="form-select"
                                            name="user"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                updateFilters('user', e.target.value)
                                            }}
                                            value={activeFilters.user || ''}
                                        >
                                            <option value="">All Users</option>
                                            {
                                                users.map((d, pos) => (
                                                    <option key={`d-${pos}`} value={d.id} >{d.name}</option>
                                                ))
                                            }

                                        </select>
                                        : ''
                                }

                            </div>

                        </div>

                        <div className="col-6 d-grid">
                            {
                                hasActiveFilters() ?
                                    <Button variant={`${hasActiveFilters() ? 'success' : 'disabled'}`} onClick={(e) => {
                                        applyFiltersAndNavigate(e, urlPath, apiPath, activeQueryString, getLinks, navigate)
                                    }} >Search</Button> :
                                    <Button variant="dark" >Search</Button>
                            }
                        </div>

                        {
                            getQueryParams() ?
                                <div className="col-6 d-grid">
                                    <Button as={Link} variant={`${isDarkTheme(authUser.theme) ? 'dark' : 'light'}`} to={urlPath} onClick={() => {
                                        setActiveFilters({ division: '', search: '', user: '' });
                                        getLinks(apiPath);
                                    }}>Clear Filters</Button>
                                </div>
                                : ''
                        }

                    </div>

                    <Accordion>
                        {
                            links.map((link) => (
                                <Accordion.Item eventKey={link.id} key={`link-mobile-${link.id}`}>
                                    <Accordion.Header>
                                        <div className="row w-100" style={{ justifyContent: "space-between", flexWrap: "nowrap" }}>
                                            <div className="col-auto" style={{ flexGrow: 1, flexShrink: 1 }}>
                                                {link.title}
                                            </div>
                                            <div className="col-auto ps-3 pe-4" style={{ flexShrink: 1 }}>
                                                <Badge bg="info" >{link.scans.total}</Badge>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>

                                        <div className="row">
                                            <div className="col-auto">
                                                Long URL:
                                            </div>
                                            <div className="col-auto">
                                                <a href={link.destination} target="_blank">{link.destination}</a>
                                            </div>

                                        </div>

                                        <hr />

                                        {
                                            link.utm_campaign ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            Campaign:
                                                        </div>
                                                        <div className="col-auto">
                                                            {link.utm_campaign}
                                                        </div>

                                                    </div>

                                                    <hr />
                                                </>
                                                : ''


                                        }


                                        <div className="row justify-content-center mobile-actions mb-4">
                                            <div className="col-auto">
                                                <Button
                                                    size="sm"
                                                    variant="success"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        downloadQR(link.id, link.image, link.icon_name, link.title, link.utm_campaign);
                                                    }}
                                                >
                                                    DOWNLOAD
                                                </Button>
                                            </div>

                                            <div className="col-auto">
                                                <Button
                                                    size="sm"
                                                    as={Link}
                                                    variant="info"
                                                    to={`/qr-code/${link.id}`}
                                                > VIEW</Button>
                                            </div>

                                            <div className="col-auto">

                                                {
                                                    link.user.id == authUser.id || authUser.isAdmin ?
                                                        <Button
                                                            variant="primary"
                                                            size="sm"
                                                            as={Link}
                                                            to={{
                                                                pathname: `/qr-code/edit/${link.id}`,
                                                                x: {
                                                                    returnUrl: urlPath
                                                                }
                                                            }}>EDIT</Button>
                                                        : ''

                                                }

                                            </div>



                                            {
                                                link.user.id == authUser.id || authUser.isAdmin ?

                                                    link.scans.total >= linkBlockLimit ?

                                                        ''
                                                        :
                                                        <div className="col-auto">
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                onClick={() => {
                                                                    deleteLinkConfirm(link)
                                                                }}
                                                            >
                                                                DELETE
                                                            </Button>
                                                        </div>

                                                    : ''
                                            }


                                        </div>


                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        }
                    </Accordion>
                </>
            : ''
    )
}

export default QRCodesMobile