import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import API from '../../API/API';
import { AuthContext } from '../../context/authContext/AuthContext';
import { PageContext } from '../../context/pageContext';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LoaderContext } from '../../context/loaderContext';
import { chartData, chartLabels, chartOptions } from '../../components/Charts/ChartUtils';
import { BarChart } from '../../components/Charts/BarChart';
import { DonutChart } from '../../components/Charts/DonutChart';
import { PieChart } from '../../components/Charts/PieChart';
import { useLocation } from 'react-router-dom';

function Dashboard() {

    const { authUser } = useContext(AuthContext);
    const today = new Date();
    const lastWeek = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));

    const { setPageInfo } = useContext(PageContext);
    const [stats, setStats] = useState(null);
    const [qrStats, setQRStats] = useState(null);
    const [queryPeriod, setQueryPeriod] = useState({ start: lastWeek, end: today });
    const [dateRange, setDateRange] = useState(7);
    const { showLoader, setShowLoader } = useContext(LoaderContext);

    const loc = useLocation();

    const sortSliceToObj = (data) => {
        let obj = {};
        
        Object.keys(data).map((r) => {
            return {
                name: r,
                hits: data[r]
            }
        }).sort((a, b) => b.hits - a.hits).slice(0, 5).forEach((i) => {
            obj[i.name] = i.hits;
        });

        return obj;

    }

    const combineStats = (arr1, arr2) => {

        let combined = {...arr1};

        const arr2Keys = Object.keys(arr2);
        for(let i = 0; i < arr2Keys.length; i++) {

            if(combined.hasOwnProperty(arr2Keys[i])) {
                combined[arr2Keys[i]] += arr2[arr2Keys[i]];
            }
            else {
                combined[arr2Keys[i]] = arr2[arr2Keys[i]];
            }
        }

        return combined;

    }
    const getStats = (from, to) => {

        if (!showLoader) setShowLoader(true);

        API.get(`all-link-stats`, { fromDate: from, toDate: to })
            .then((res) => {

                let content = res.data.content;
                let hits = content.hits;

                let allHits = {};
                let uniqueHits = {};
                [...hits].sort((a, b) => b.total - a.total).slice(0, 5).map((h) => allHits[`${h.title} (${h.key})`] = h.total);
                [...hits].sort((a, b) => b.unique - a.unique).slice(0, 5).map((h) => uniqueHits[`${h.title} (${h.key})`] = h.unique);

                let campaigns = sortSliceToObj(content.campaigns);
                let referrers = sortSliceToObj(content.referrers);
                let cities = sortSliceToObj(content.cities);
                let regions = sortSliceToObj(content.regions);

                const checkEmpty = (obj) => {
                    return Object.keys(obj).length ? obj : null;
                }

                API.get(`all-qr-stats`, { fromDate: from, toDate: to })
                    .then((qrRes) => {
                        
                        let qrContent = qrRes.data.content;
                        
                        let qrHits = qrContent.hits;

                        let allQRHits = {};
                        let uniqueQRHits = {};
                        [...qrHits].sort((a, b) => b.total - a.total).slice(0, 5).map((h) => allQRHits[`${h.title} (${h.key})`] = h.total);
                        [...qrHits].sort((a, b) => b.unique - a.unique).slice(0, 5).map((h) => uniqueQRHits[`${h.title} (${h.key})`] = h.unique);

                        let qrCampaigns = qrContent.campaigns ? sortSliceToObj(qrContent.campaigns) : null;
                        let qrCities = qrContent.cities ? sortSliceToObj(qrContent.cities) : null;
                        let qrRegions = qrContent.regions ? sortSliceToObj(qrContent.regions) : null;


                        setQRStats({
                            allQRHits: checkEmpty(allQRHits),
                            uniqueQRHits: checkEmpty(uniqueQRHits),
                            qrCampaigns: checkEmpty(qrCampaigns),
                            qrCities: checkEmpty(qrCities),
                            qrRegions: checkEmpty(qrRegions)
                        })
                        
                        setStats({
                            allHits: checkEmpty(allHits),
                            uniqueHits: checkEmpty(uniqueHits),
                            campaigns: checkEmpty(combineStats(campaigns, qrCampaigns)),
                            referrers: checkEmpty(referrers),
                            cities: checkEmpty(combineStats(cities, qrCities)),
                            regions: checkEmpty(combineStats(regions, qrRegions)),
                        })
                        
                        setShowLoader(false);

                    })
                    .catch((err) => {
                        console.log(err)
                        setShowLoader(false);
                    });


            })
            .catch((err) => {
                console.log(err)
                setShowLoader(false);
            });
    }

    useEffect(() => {
        setPageInfo({ title: "Dashboard", path: loc.pathname, hasCreate: false });
        getStats(queryPeriod.start, queryPeriod.end);
    }, []);

    const updateDateRange = (e) => {
        const q = e.target.value;

        setDateRange(q);

        if (q < 0) return;
        const startDate = new Date(today.getTime() - (q * 24 * 60 * 60 * 1000));
        const endDate = Number(q) === 1 ? new Date(today.getTime() - (1 * 24 * 60 * 60 * 1000)) : today;

        setQueryPeriod({ start: startDate, end: endDate });
        getStats(startDate, endDate);
    }

    return (
        <Container fluid>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3 mb-2">
                            <div className="input-group" style={{ flexWrap: "nowrap" }}>
                                <label htmlFor="q-range" className="input-group-text cursor-pointer" >Period</label>
                                <select
                                    id="q-range"
                                    className="form-select"
                                    onChange={updateDateRange}
                                    value={dateRange}
                                >
                                    <option value={-1}>Custom</option>
                                    <option value={0}>Today</option>
                                    <option value={1}>Yesterday</option>
                                    <option value={7}>Last 7 Days</option>
                                    <option value={30}>Last 30 Days</option>
                                    <option value={90}>Last 90 Days</option>
                                    <option value={180}>Last 180 Days</option>
                                    <option value={365}>Last 365 days</option>

                                </select>
                            </div>
                        </div>
                        <div className="col-md-3  mb-2">
                            <div className="input-group" style={{ flexWrap: "nowrap" }}>
                                <label htmlFor="q-from" className="input-group-text cursor-pointer" >From</label>
                                <DatePicker
                                    id="q-from"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    selected={queryPeriod.start}
                                    onChange={(date) => {

                                        setDateRange(-1);
                                        if (queryPeriod.end < date) {
                                            setQueryPeriod({ start: date, end: date });
                                            getStats(date, date);
                                        }
                                        else {
                                            setQueryPeriod({ ...queryPeriod, start: date });
                                            getStats(date, queryPeriod.end);
                                        }

                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 mb-2">
                            <div className="input-group" style={{ flexWrap: "nowrap" }}>
                                <label htmlFor="q-to" className="input-group-text cursor-pointer" >To</label>
                                <DatePicker
                                    id="q-to"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    selected={queryPeriod.end}
                                    onChange={(date) => {
                                        setDateRange(-1);
                                        if (queryPeriod.start > date) {
                                            setQueryPeriod({ start: date, end: date });
                                            getStats(date, date);
                                        }
                                        else {
                                            setQueryPeriod({ ...queryPeriod, end: date });
                                            getStats(queryPeriod.start, date);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {
                !showLoader ?

                    ((!stats || !stats.allHits) && (!qrStats || !qrStats.allQRHits) && !showLoader) ?
                        <div className="alert alert-warning">There are no stats to display for the queried period.</div>
                        :

                        (stats && stats.allHits) || (qrStats && qrStats.allQRHits) ?
                            <>
                                <div className="row gx-5 gy-4">

                                    {
                                        stats.allHits && Object.keys(stats.allHits).length ?
                                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">TOP URL CLICKS</h5>
                                                    </div>
                                                    <div className="card-body ">
                                                        <DonutChart chartData={chartData('', chartLabels(stats.allHits, 5), stats.allHits, authUser.theme)} options={chartOptions('', { show: true }, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>
                                            : ''

                                    }

                                    {
                                        stats.uniqueHits && Object.keys(stats.uniqueHits).length ?
                                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">TOP UNIQUE URL CLICKS</h5>
                                                    </div>
                                                    <div className="card-body ">
                                                        <DonutChart chartData={chartData('', chartLabels(stats.uniqueHits, 5), stats.uniqueHits, authUser.theme)} options={chartOptions('', { show: true }, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }


                                    {
                                        stats.campaigns && Object.keys(stats.campaigns).length ?
                                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">TOP CAMPAIGNS</h5>
                                                    </div>
                                                    <div className="card-body ">
                                                        <PieChart chartData={chartData('', chartLabels(stats.campaigns, 5), stats.campaigns, authUser.theme, [])} options={chartOptions('', { show: true }, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }

                                    {
                                        stats.referrers && Object.keys(stats.referrers).length ?
                                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">TOP REFERRERS</h5>
                                                    </div>
                                                    <div className="card-body ">
                                                        <PieChart chartData={chartData('', chartLabels(stats.referrers, 5), stats.referrers, authUser.theme, [])} options={chartOptions('', { show: true }, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }

                                    {
                                        stats.cities && Object.keys(stats.cities).length ?
                                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">TOP CITIES</h5>
                                                    </div>
                                                    <div className="card-body ">
                                                        <BarChart chartData={chartData('', chartLabels(stats.cities, 5), stats.cities, authUser.theme, [])} options={chartOptions('', {}, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }

                                    {
                                        stats.regions && Object.keys(stats.regions).length ?
                                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">TOP REGIONS</h5>
                                                    </div>
                                                    <div className="card-body ">
                                                        <BarChart chartData={chartData('', chartLabels(stats.regions, 5), stats.regions, authUser.theme, [])} options={chartOptions('', {}, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }

                                    {
                                        qrStats.allQRHits && Object.keys(qrStats.allQRHits).length ?
                                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">TOP QR SCANS</h5>
                                                    </div>
                                                    <div className="card-body ">
                                                        <DonutChart chartData={chartData('', chartLabels(qrStats.allQRHits, 5), qrStats.allQRHits, authUser.theme)} options={chartOptions('', { show: true }, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>
                                            : ''

                                    }

                                    {
                                        qrStats.uniqueQRHits && Object.keys(qrStats.uniqueQRHits).length ?
                                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-heading">TOP UNIQUE QR SCANS</h5>
                                                    </div>
                                                    <div className="card-body ">
                                                        <DonutChart chartData={chartData('', chartLabels(qrStats.uniqueQRHits, 5), qrStats.uniqueQRHits, authUser.theme)} options={chartOptions('', { show: true }, authUser.theme || 'default')} height={300} />
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }

                                    
                                </div>
                            </>

                            : <div className="alert alert-warning">There are no stats to display for the queried period.</div>
                    : ''
            }

        </Container>
    )
}

export default Dashboard
