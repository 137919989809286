import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import API from '../../API/API';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { PageContext } from '../../context/pageContext';
import { labelHTML, removeError } from '../../utils/formUtils';
import { objIsEmpty } from '../../utils/objectUtils';
import { showConfirmation, showToast } from '../../utils/utils';

function EditUser() {

    const { authUser } = useContext(AuthContext);
    const { setPageInfo } = useContext(PageContext);
    const { showLoader, setShowLoader } = useContext(LoaderContext);
    const [divisions, setDivisions] = useState([]);

    const [user, setUser] = useState(null);

    const [errors, setErrors] = useState({});

    const loc = useLocation();
    const params = useParams();

    const getUser = () => {
        if (!showLoader) setShowLoader(true);
        API.get(`user/${params.id}`)
            .then((res) => {
                const u = res.data.content;
                setUser({ ...u, divisionId: u.division.id, isAdmin: u.is_admin });
                setPageInfo({ title: `Edit User ${u?.firstname} ${u?.lastname}`, path: loc.pathname, hasCreate: false });
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
            });
    }

    const getDivisions = () => {

        if (!showLoader) setShowLoader(true);
        API.get('divisions')
            .then((res) => {
                const ds = res.data.content;
                setDivisions(ds);
            })
            .catch((err) => {
                setShowLoader(false);
            });

        getUser();
    }

    useEffect(() => {
        // setPageInfo({title: "Edit User", path: loc.pathname, hasCreate: false});
        getDivisions();

    }, [])


    const hasErrors = () => {
        if (!objIsEmpty(errors)) return true;
        return false;
    }

    const updateUser = (e) => {
        e.preventDefault();
        if (hasErrors()) {
            showConfirmation({ title: 'Errors', text: `There are one or more errors that need fixing before you can update your profile.` });
        }
        else {

            setShowLoader(true);
            API.put(`user/update/${user.id}`, user)
                .then((res) => {

                    if (res.status === 200) {
                        const newData = res.data.content;
                        const userInfo = {
                            avatar: newData.avatar,
                            division: newData.division,
                            email: newData.email,
                            firstname: newData.firstname,
                            id: newData.id,
                            isAdmin: newData.is_admin,
                            lastname: newData.lastname
                        }

                        showToast({ title: 'User updated successfully.' });
                        setUser({ ...userInfo, divisionId: userInfo.division.id });
                    }

                    setShowLoader(false);
                })
                .catch((err) => {
                    console.log(err)
                    setShowLoader(false);
                });
        }
    }

    return (

        user ?
            authUser.isAdmin ?
                <Container fluid>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">

                                    {
                                        user.avatar ?
                                            <>
                                                <div className="row">
                                                    <div className="col-12 mb-3">
                                                        <div className="row user-profile-image">
                                                            <div className="col-12 mb-3 col-md-3">
                                                                <img src={user.avatar} alt="" />
                                                            </div>
                                                            <div className="col-12 mb-3 col-md-9">

                                                                <div className="row">

                                                                    <div className="col-12 mb-3">
                                                                        {labelHTML("Profile Picture", 'avatar', true, 'profile-avatar', errors)}
                                                                        <input
                                                                            id="user-avatar"
                                                                            type="text"
                                                                            placeholder="Image URL"
                                                                            value={user.avatar}
                                                                            className={`form-control ${errors.avatar && errors.avatar.length ? 'is-invalid' : ''}`}
                                                                            onChange={(e) => {

                                                                                setUser({ ...user, avatar: e.target.value })
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                removeError('avatar', errors, setErrors);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 text-end">
                                                                        {
                                                                            user.avatar &&
                                                                            <Button variant="danger" size="sm" className="me-2" onClick={() => {
                                                                                setUser({ ...user, avatar: '' })
                                                                            }}>Remove Image</Button>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <hr />
                                            </>
                                            : ''
                                    }


                                    <div className="row">
                                        <div className="col-12 col-md-6 mb-3">
                                            {labelHTML("Email Address", 'email', true, 'user-email', '', errors)}
                                            <input
                                                id="user-email"
                                                type="text"
                                                value={user.email || ''}
                                                required
                                                className={`form-control ${errors.email && errors.email.length ? 'is-invalid' : ''}`}
                                                onChange={(e) => {
                                                    setUser({ ...user, email: e.target.value })
                                                }}
                                                onBlur={(e) => {
                                                    removeError('email', errors, setErrors);

                                                    if (!e.target.value.length) {
                                                        setErrors({ ...errors, email: "Please enter the user's email address." })
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="col-12 col-md-6 mb-3">
                                            {labelHTML("Division", 'divisionId', true, 'user-division', '', errors)}

                                            <select
                                                id="user-division"
                                                value={user.divisionId || 1}
                                                className={`form-select  ${errors.divisionId && errors.divisionId.length ? 'is-invalid' : ''}`}
                                                onChange={(e) => {
                                                    removeError('divisionId', errors, setErrors);
                                                    if (!e.target.value || !e.target.value.length) {
                                                        setErrors({ ...errors, divisionId: "Please enter the user's division." })
                                                    }
                                                    else {
                                                        setUser({ ...user, divisionId: e.target.value })
                                                    }
                                                }}
                                                required
                                            >
                                                {
                                                    divisions &&
                                                    divisions.map((d, pos) => (
                                                        <option key={`division-${pos}`} value={d.id}>{d.name}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>

                                        <div className="col-12 col-md-6 mb-3">
                                            {labelHTML("First Name", 'firstname', true, "user-firstname", '', errors)}
                                            <input
                                                id="user-firstname"
                                                type="text"
                                                value={user.firstname || ''}
                                                required
                                                className={`form-control ${errors.firstname && errors.firstname.length ? 'is-invalid' : ''}`}
                                                onChange={(e) => {
                                                    setUser({ ...user, firstname: e.target.value })
                                                }}
                                                onBlur={(e) => {
                                                    removeError('firstname', errors, setErrors);

                                                    if (!e.target.value.length) {
                                                        setErrors({ ...errors, firstname: "Please enter the user's first name." });
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="col-12 col-md-6 mb-3">
                                            {labelHTML("Surname", 'lastname', true, "user-lastname", '', errors)}
                                            <input
                                                id="user-lastname"
                                                type="text"
                                                value={user.lastname || ''}
                                                required
                                                className={`form-control ${errors.lastname && errors.lastname.length ? 'is-invalid' : ''}`}
                                                onChange={(e) => {
                                                    setUser({ ...user, lastname: e.target.value })
                                                }}
                                                onBlur={(e) => {
                                                    removeError('lastname', errors, setErrors);

                                                    if (!e.target.value.length) {
                                                        setErrors({ ...errors, lastname: "Please enter the user's surname." })
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>

                                    <hr />

                                    <div className="row">

                                        <div className="col-12 col-md-12 mb-3">
                                            {
                                                Number(user.id) === Number(authUser.id) && authUser.isAdmin ?
                                                    'You have admin privileges. If you no longer wish to have admin permissions, please ask another admin to update your profile.'
                                                    :

                                                    <Form.Group className="mb-3" controlId="user-admin">
                                                        <Form.Check type="checkbox" label={`Give ${user.firstname} ${user.lastname} admin privileges.`}
                                                            checked={user.isAdmin || false}
                                                            onChange={(e) => {
                                                                setUser({ ...user, isAdmin: e.target.checked })
                                                            }}
                                                        />
                                                    </Form.Group>
                                            }

                                        </div>

                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-12 text-end">
                                            <Button variant="success" onClick={updateUser}>Save Profile</Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </Container>
                : <div className="alert alert-danger">You don't have permission to edit users.</div>
            : ''

    )
}

export default EditUser