import React, { useContext } from 'react'
import { Button, Table } from 'react-bootstrap';
import { Edit, Search, Trash } from 'react-feather';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/authContext/AuthContext';
import { LoaderContext } from '../../context/loaderContext';
import { getQueryParams } from '../../utils/urlUtils';
import { applyFiltersAndNavigate, isDarkTheme } from '../../utils/utils';

function UsersDesktop(
    {
        users,
        divisions,
        updateFilters,
        activeFilters,
        urlPath,
        apiPath,
        getUsers,
        activeQueryString,
        setActiveFilters,
        deleteUserConfirm,
        hasActiveFilters,
        navigate
    }
) {
    const { showLoader } = useContext(LoaderContext);
    const { authUser } = useContext(AuthContext);

    return (

        !showLoader || users.length ?
            users.length ?

                <>
                    <div className="row mb-4 align-items-center">
                        <div className="col-md-8">
                            <div className="input-group">
                                <span className={`input-group-text`} >Search / Filter</span>
                                {
                                    divisions.length ?

                                        <select
                                            className="form-select"
                                            name="division"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                updateFilters('division', e.target.value)
                                            }}
                                            value={activeFilters.division || ''}
                                        >
                                            <option value="">All Divisions</option>
                                            {
                                                divisions.map((d, pos) => (
                                                    <option key={`d-${pos}`} value={d.id} >{d.name}</option>
                                                ))
                                            }

                                        </select>
                                        : ''
                                }

                                {
                                    <input
                                        value={activeFilters.search}
                                        type="text"
                                        className="form-control"
                                        placeholder="Name or email"
                                        name="search"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            updateFilters('search', e.target.value)
                                        }}

                                        onKeyUp={(e) => {

                                            if (e.key === "Enter") {
                                                applyFiltersAndNavigate(e, urlPath, apiPath, activeQueryString, getUsers, navigate);
                                            }
                                        }}
                                    />
                                }

                                {
                                    hasActiveFilters() ?
                                        <span className={`input-group-text cursor-pointer ${hasActiveFilters() ? 'bg-success' : ''}`} onClick={(e) => {
                                            applyFiltersAndNavigate(e, urlPath, apiPath, activeQueryString, getUsers, navigate)
                                        }} ><Search color={`${hasActiveFilters() ? '#fff' : '#353C43'}`} /></span> :
                                        <span className={`input-group-text`} ><Search /></span>
                                }
                            </div>

                        </div>
                        {
                            getQueryParams() ?
                                <div className="col-md-4 text-end">
                                    <Button as={Link} variant="light" size="sm" to={urlPath} onClick={() => {
                                        setActiveFilters({ division: '', search: '' });
                                        getUsers(apiPath);
                                    }}>Clear Filters</Button>
                                </div>
                                : ''
                        }

                    </div>


                    <Table striped bordered hover className="users-table">
                        <thead>
                            <tr>
                                <th className="user-avatar"></th>
                                <th className="user-name">Name</th>
                                <th className="user-email">Email</th>
                                <th className="division-logo">Division</th>
                                <th className="user-role">Role</th>
                                <th className="short-urls">Links Shortened</th>
                                <th className="actions">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users.map((user) => (

                                    <tr key={user.id}>
                                        <td className="user-avatar">
                                            <div>
                                                <img src={user.avatar || `https://ui-avatars.com/api/?color=FFFFFF&background=E02124&size=200&length=1&name=${user.firstname}`} alt={user.firstname} />
                                            </div>
                                        </td>

                                        <td className="user-name">
                                            {user.firstname} {user.lastname}
                                            {Number(user.id) === Number(authUser.id) ? <span className="badge bg-success rounded-pill ms-2">
                                                YOU
                                            </span>

                                                : ''}
                                        </td>

                                        <td className="user-email">
                                            {user.email}
                                        </td>

                                        <td className="division-logo">
                                            {
                                                user.division ?
                                                    user.division.logo ?
                                                        <img src={isDarkTheme(authUser.theme) ? user.division.logo_alt : user.division.logo} alt={user.division.name} />
                                                        : user.division.name
                                                    : "Unknown"
                                            }
                                        </td>

                                        <td className="user-role">
                                            {user.is_admin ? 'Admin' : 'User'}
                                        </td>

                                        <td className="short-urls">
                                            {user.links_count}
                                        </td>

                                        <td className="actions">
                                            <div className="container">
                                                <div className="row">
                                                    {
                                                        authUser.isAdmin ?
                                                            <div className="col-auto">

                                                                <Link to={{
                                                                    pathname: authUser.id == user.id ? '/profile' : `/user/edit/${user.id}`,
                                                                    x: {
                                                                        returnUrl: urlPath
                                                                    }
                                                                }}>
                                                                    <Edit size={16} className="cursor-pointer text-info" />
                                                                </Link>
                                                            </div>
                                                            : ''
                                                    }

                                                    {
                                                        authUser.isAdmin && Number(user.id) !== Number(authUser.id) ?
                                                            <div className="col-auto">

                                                                <Trash size={16} className="cursor-pointer text-danger" onClick={() => {
                                                                    deleteUserConfirm(user)
                                                                }} />
                                                            </div>
                                                            : ''
                                                    }

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </Table>
                </>
                :
                hasActiveFilters()
                    ? <div className="alert alert-warning">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-auto">There are no users matching your search.</div>
                            <div className="col-auto">
                                <Button as={Link} variant="light" size="sm" to={urlPath} onClick={() => {

                                    setActiveFilters({ division: '', search: '' });
                                    getUsers(apiPath);
                                }}>Clear Filters</Button>
                            </div>
                        </div>

                    </div>
                    : <div className="alert alert-warning">There are no users.</div>
            : ''
    )
}

export default UsersDesktop